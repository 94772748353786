/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */
import { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import MobileStepper from "@mui/material/MobileStepper";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import { Box } from "@mui/material";
import appColors from "../../Assets/AppTheme/appColors";
import {
  SetLoadinngFalse,
  StoreBannerImages,
} from "../../Redux/actions/getBannersAction";
import { useDispatch, useSelector } from "react-redux";
import { bannerRedirect } from "../../Assets/Constants/AppMetaData";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

export default function BanneRDetails({ data, dataURI, pageHome }) {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const bannersDetails = useSelector((state) => state.bannersDetails);
  const [activeStep, setActiveStep] = useState(0);

  // Function for handling scroll effect
  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  useEffect(() => {
    if (bannersDetails.bannerImagesFlag === false) {
      // Array for storing banner files
      let array = [];

      // map function for adding images in array
      dataURI.forEach((step) => {
        if (step !== "") {
          array.push(step);
        }
      });

      let cleanedArray = array.filter((item) => item !== "");
      // Set function for removing duplicates
      let uniqueArray = [...new Set(cleanedArray)];
      // Variable for storing steps length for scroll bar

      const imageUrls = uniqueArray.filter((url) => {
        // Use a regular expression to match data URLs with image MIME types (e.g., image/png)
        const regex = /^data:image\/(png|jpeg|jpg|gif|bmp|webp);base64,/i;
        return regex.test(url.imageDataUrl);
      });

      let count = 0;
      const new_array = data?.bannersData.map((step, index) => {
        if (step.file_names.length > 0 && imageUrls.length > 0) {
          // Add a new key to the current element
          const updatedStep = {
            ...step,
            newimage: imageUrls[count]?.imageDataUrl,
            // Add the new key with its value
          };
          count++; // Increment count for the next iteration
          return updatedStep;
        } else {
          // Return the element unchanged if the condition is not met
          return step;
        }
      });

      // Dispatch the action to store new_array in Redux store
      dispatch(StoreBannerImages(new_array));
    }
  }, [dataURI]);

  const maxSteps = bannersDetails?.bannerImages?.length;

  //useeffect for blocking api hit
  useEffect(() => {
    if (
      bannersDetails?.bannerImages !== undefined &&
      bannersDetails?.bannerImages?.length > 0
    ) {
      const imageFlag = bannersDetails?.bannerImages?.find(
        (data) => data !== "" && data?.newimage !== undefined
      );
      if (imageFlag) {
        dispatch(SetLoadinngFalse(true));
      }
    }
  }, [bannersDetails?.bannerImages]);

  //function to redirect banner based on provided value
  const handelBannerClick = (data) => {
    switch (data?.app_page) {
      case bannerRedirect.HOME:
        break;
      case bannerRedirect.ACCOUNTS:
        history.push("/dashboard/myaccounts");
        break;
      case bannerRedirect.DEPOSITS:
        history.push("/dashboard/deposit");
        break;
      case bannerRedirect.WALLET:
        history.push("/dashboard/wallet");
        break;
      case bannerRedirect.SUPPORT:
        history.push("/dashboard/support");
        break;
      case bannerRedirect.SUBSCRIPTION:
        history.push("/dashboard/subscriptionList");
        break;
      case bannerRedirect.REFER_EARN:
        history.push("/dashboard/referandearn");
        break;
      case bannerRedirect.PROFILE:
        history.push("/dashboard/settings");
        break;
      case bannerRedirect.PAYMENTS:
        history.push("/dashboard/transactions");
        break;
      case bannerRedirect.MY_ORDERS:
        history.push("/dashboard/myorders");
        break;
      case bannerRedirect.GIFTCARD:
        history.push("/dashboard/giftcards");
        break;
      case bannerRedirect.BOOK_NOW:
        history.push("/dashboard/booknow");
        break;
    }
  };
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box
        sx={{
          maxWidth: "100%",
          flexGrow: 1,
          boxShadow: 3,
          borderRadius: ".7rem",
          marginLeft: ".4rem",
          marginRight: ".4rem",
        }}
      >
        <AutoPlaySwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
          interval={4000}
        >
          {bannersDetails?.bannerImages?.map((step, index) => (
            <div key={index} onClick={() => handelBannerClick(step)}>
              {step.message !== undefined &&
              step.message !== "" &&
              step.newimage === undefined ? (
                <>
                  {Math.abs(activeStep - index) <= 2 ? (
                    <Box
                      sx={{
                        height: pageHome ? "150px" : "150px",
                        maxWidth: "100%",
                        borderRadius: ".7rem",
                        backgroundColor: "#fff",
                      }}
                      alt={index}
                    >
                      <Paper
                        square
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          height: 35,
                          pl: 2,
                          background: appColors.headerTextColor, // Add your background image here
                          boxShadow: 2,
                          borderRadius: ".7rem .7rem 0 0 ",
                        }}
                      >
                        <Typography sx={{ color: "#fff" }}>
                          {step.banner_header}
                        </Typography>
                      </Paper>
                      <Typography
                        sx={{ marginLeft: ".5rem", marginTop: ".5rem" }}
                      >
                        {step.message}
                      </Typography>
                    </Box>
                  ) : null}
                </>
              ) : (
                <>
                  {Math.abs(activeStep - index) <= 2 ? (
                    <Box>
                      {step !== "" && step.newimage !== undefined ? (
                        <Box
                          component="img"
                          sx={{
                            height: pageHome ? "150px" : "150px",
                            borderRadius: ".7rem",
                            display: "block",
                            overflow: "hidden",
                            width: "100%",
                          }}
                          src={step.newimage}
                          alt="Banner image"
                        />
                      ) : (
                        ""
                      )}
                    </Box>
                  ) : null}
                </>
              )}{" "}
            </div>
          ))}
        </AutoPlaySwipeableViews>
      </Box>
      {maxSteps > 0 ? (
        <MobileStepper
          sx={{
            borderRadius: "0 0 .7rem .7rem",
            justifyContent: "center",
            backgroundColor: "transparent",
            marginTop: "1rem",
            padding: 0,
            "& .MuiMobileStepper-dots .MuiMobileStepper-dot": {
              width: 35, // Set your desired width
              height: 2, // Set your desired height
              borderRadius: 1,
              backgroundColor: "#caedff",
            },
            "& .MuiMobileStepper-dots .MuiMobileStepper-dotActive": {
              backgroundColor: "#068fff", // Set your desired active color
            },
          }}
          steps={maxSteps}
          variant="dots"
          position="static"
          activeStep={activeStep}
        />
      ) : (
        ""
      )}
    </Box>
  );
}
