/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Tahir Shaik
 * @description This file contains all Profile related Actions
 * @date 29/05/23
 * @version 0.0.1 - Initial Release
 */

// Importing the Libraries & Other Functions
import axios from "axios";
import actionType from "../actions/actionTypes";
import useToastMessage from "../../Hooks/useToast";
import appConstants from "../../Assets/AppMeta/appConstants";
import CustomConsole from "../../coustomConsole";
/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Tahir Shaik
 * @description This Function will perform the Log-in action, WHere user should enter their Details and Login to BookWater
 * @date 29/05/23
 * @version 0.0.1 - Initial Release
 * @param {email_phone, password, storage_type}
 * @retruns {Dispatch the Response (JWT Token and error, msg to Reducer)}
 */
export const logIn = ({ email_phone, password, storage_type }) => {
  let authMsgType = appConstants.appMessageType.ERROR;
  return async (dispatch) => {
    dispatch({
      type: actionType.SIGN_IN_LOADING,
    });
    await axios
      .post(`${process.env.REACT_APP_BW_URL}/SCM/login/consumer`, {
        email_phone,
        password,
      })
      .then((res) => {
        if (!res.data.error) {
          if (storage_type === true) {
            localStorage.setItem("token", res.data.tkn);
          } else {
            sessionStorage.setItem("token", res.data.tkn);
          }
          dispatch({
            type: actionType.SIGN_IN,
            payload: res.data,
          });
          authMsgType = appConstants.appMessageType.SUCCESS;
          useToastMessage({
            msgType: authMsgType,
            msg: res.data.msg,
          });
          return res?.data;
        } else {
          authMsgType = appConstants.appMessageType.ERROR;
          useToastMessage({
            msgType: authMsgType,
            msg: res.data.msg,
          });
          return res?.data;
        }
      })
      .catch((error) => {
        dispatch({
          type: actionType.SIGN_IN_LOADING_STOP,
        });
        useToastMessage({
          msgType: appConstants.appMessageType.ERROR,
          msg: error.response.data.msg,
        });
        return error?.response?.data;
      });
  };
};
/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Tahir Shaik
 * @description This Function will perform the Sign-Up action
 * @date 08/07/23
 * @version 0.0.1 - Initial Release
 * @param {email_phone, password, storage_type}
 * @retruns {Dispatch the Response (JWT Token and error, msg to Reducer)}
 */
export const signUp = (user) => {
  let authMsgType = appConstants.appMessageType.ERROR;
  return async (dispatch) => {
    await axios
      .post(`${process.env.REACT_APP_BW_URL}/SCM/signup/consumer`, user)
      .then((res) => {
        CustomConsole(res);
        if (!res.data.error) {
          sessionStorage.setItem("signup_jwt", res.data.signup_jwt);
          authMsgType = appConstants.appMessageType.SUCCESS;
        } else {
          authMsgType = appConstants.appMessageType.ERROR;
        }
        dispatch({
          type: actionType.SIGN_UP,
          payload: res.data,
        });
        useToastMessage({
          msgType: authMsgType,
          msg: res.data.msg,
        });
        return res?.data;
      })
      .catch((error) => {
        useToastMessage({
          msgType: authMsgType,
          msg: error.response.data.msg,
        });
        return error?.res?.data;
      });
  };
};

/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Tahir Shaik
 * @description This Function will perform the Search Availability of PDW Actions
 * @date 08/06/23
 * @version 0.0.1 - Initial Release
 * @param {pincode}
 * @retruns {Dispatch the Response (JWT Token and error, msg to Reducer)}
 */
export const searchPdwAvailability = ({ pincode }) => {
  let authMsgType = appConstants.appMessageType.ERROR;
  return (dispatch) => {
    axios
      .post(`${process.env.REACT_APP_BW_URL}/TM/system/searchAreasbyPincode`, {
        pincode,
      })
      .then((res) => {
        if (!res.data.error) {
          useToastMessage({
            msgType: appConstants.appMessageType.SUCCESS,
            msg: res.data.msg,
          });
          sessionStorage.setItem("signup_jwt", res.data.signup_jwt);
        } else {
          useToastMessage({
            msgType: appConstants.appMessageType.ERROR,
            msg: "The pincode is currently unavailable for service but will be available in your area soon.",
          });
        }
        dispatch({
          type: actionType.SEARCH_PDW_AVAILABILITY,
          payload: res.data,
        });
      })
      .catch((error) => {
        useToastMessage({
          msgType: appConstants.appMessageType.ERROR,
          msg: error.response.data.msg,
        });
      });
  };
};

/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Pravesh
 * @description This Function will perform the sending OTP to Phone to verify and then proceed to create new account
 * @date 14/06/23
 * @version 0.0.1 - Initial Release
 * @param {email, phone}
 * @returns {Dispatch the Response (JWT Token and error, msg to Reducer)}
 */
export const verifyPhone = (phone_pin, referral_code) => {
  const signup_jwt = sessionStorage.getItem("signup_jwt");
  const errorResponse = {
    error: true,
  };
  return async (dispatch) => {
    await axios
      .post(`${process.env.REACT_APP_BW_URL}/SCM/verify/consumer_phone`, {
        signup_jwt,
        phone_pin,
        referral_code,
      })
      .then((res) => {
        CustomConsole(res);
        if (!res.data.error) {
          sessionStorage.setItem("signup_jwt", res.data.signup_jwt);
        } else {
          useToastMessage({
            msgType: appConstants.appMessageType.ERROR,
            msg: "Invalid OTP",
          });
        }
        dispatch({
          type: actionType.PHONE_VERIFICATION,
          payload: res.data,
        });
      })
      .catch((error) => {
        CustomConsole(error);
        dispatch({
          type: actionType.PHONE_VERIFICATION,
          payload: error.response.data,
        });
        useToastMessage({
          msgType: appConstants.appMessageType.ERROR,
          msg: "Please Enter Valid OTP",
        });
      });
  };
};

/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Pravesh
 * @description This Function will perform the sending OTP to verify and then proceed to set password
 * @date 14/06/23
 * @version 0.0.1 - Initial Release
 * @param {email, phone}
 * @returns {Dispatch the Response (JWT Token and error, msg to Reducer)}
 */

export const forgotPasswordSendOTP = (email, phone) => {
  let authMsgType = appConstants.appMessageType.ERROR;

  const catchError = {
    error: true,
    msg: "User not registered",
  };
  return (dispatch) => {
    axios
      .post(`${process.env.REACT_APP_BW_URL}/SCM/resetpass/user`, {
        email,
        phone,
      })
      .then((res) => {
        // CustomConsole("forgotPasswordUserVerify Response:\n" + res);
        CustomConsole(res);
        if (!res.data.error) {
          sessionStorage.setItem("checksum", res.data.checksum);
          authMsgType = appConstants.appMessageType.SUCCESS;
        } else {
          authMsgType = appConstants.appMessageType.ERROR;
        }
        dispatch({
          type: actionType.FORGOT_PASSWORD_EMAIL_PHONE,
          payload: res.data,
        });
        useToastMessage({
          msgType: authMsgType,
          msg:
            res.data.error === false
              ? "Verified successfully"
              : "Please check your Email/Phone",
        });
      })
      .catch((error) => {
        // CustomConsole("forgotPasswordUserVerify Error:\n" + error);
        CustomConsole(error);
        error = error + "";
        if (error.includes("400")) {
          dispatch({
            type: actionType.FORGOT_PASSWORD_EMAIL_PHONE,
            payload: catchError,
          });
          useToastMessage({
            msgType: appConstants.appMessageType.ERROR,
            msg: "User Not Registered",
          });
        } else {
          useToastMessage({
            msgType: appConstants.appMessageType.ERROR,
            msg: "Please try after sometime",
          });
        }
      });
  };
};
export const forgotPasswordNewPassword = (new_password, verify_code) => {
  let authMsgType = appConstants.appMessageType.ERROR;
  const checksum = sessionStorage.getItem("checksum");

  const catchError = {
    error: true,
    msg: "Incorrect OTP, Please enter valid OTP",
  };
  return (dispatch) => {
    axios
      .put(`${process.env.REACT_APP_BW_URL}/SCM/resetpass/verify_user`, {
        checksum,
        new_password,
        verify_code,
      })
      .then((res) => {
        // CustomConsole("forgotPasswordUserVerify Response:\n" + res);
        CustomConsole(res);
        if (!res.data.error) {
          sessionStorage.removeItem("checksum");
          authMsgType = appConstants.appMessageType.SUCCESS;
        } else {
          authMsgType = appConstants.appMessageType.ERROR;
        }
        dispatch({
          type: actionType.FORGOT_PASSWORD_NEW_PASSWORD,
          payload: res.data,
        });
        useToastMessage({
          msgType: authMsgType,
          msg: res.data.msg,
        });
      })
      .catch((error) => {
        // CustomConsole("forgotPasswordUserVerify Error:\n" + error);
        CustomConsole(error);
        // error = error + "";
        if (error.response.data.error === true) {
          dispatch({
            type: actionType.FORGOT_PASSWORD_NEW_PASSWORD,
            payload: error.response.data,
          });
          useToastMessage({
            msgType: appConstants.appMessageType.ERROR,
            msg: error.response.data.msg,
          });
        } else {
          useToastMessage({
            msgType: appConstants.appMessageType.ERROR,
            msg: "Please try after sometime",
          });
        }
      });
  };
};
