/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Gowtham Prasath
 * @description
 *
 */
import { Grid } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import FaqHeaderContent from "./FaqHeaders";
import FaqAnswerContent from "./FaqHeadersContent";
import { faqMainStyles } from "./FaqStyles";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { toggleState } from "../../Redux/actions/appMetaDataAction";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";

export default function FaqMainComponent() {
  const history = useHistory();
  const appMetaDataReducer = useSelector((state) => state.appMetaDataDetails);
  const dispatch = useDispatch();
  const [bookingText, setBookingText] = useState("CONTACT");
  const [showText, setShowText] = useState(false);
  const [expanded, setExpanded] = useState("panel1");

  const handelShowText = () => {
    setShowText(true);
    dispatch(toggleState());
  };

  useEffect(() => {
    if (appMetaDataReducer.toggleState === false) {
      setShowText(false);
    }
  }, [appMetaDataReducer.toggleState]);

  //useeffect to set accrodian default open
  useEffect(() => {
    if (expanded !== "panel1") {
      setExpanded("panel1");
    }
  }, [bookingText]);
  return (
    <>
      <Grid
        container
        sx={{ marginTop: "4rem", marginBottom: { xs: "4rem", lg: 0 } }}
      >
        {/* <Grid item xs={12} sx={faqMainStyles.textHeading} onClick={handelShowText}>
                    SUPPORT - FAQ - {bookingText}
                </Grid>
                <Grid item xs={12} sx={faqMainStyles.textHeadingFaq}>
                    Frequently Asked Questions
                </Grid> */}
        <Grid
          container
          spacing={2}
          sx={{ marginTop: ".1rem", justifyContent: "center" }}
        >
          {(!showText && window.innerWidth <= 600) ||
          window.innerWidth > 600 ? (
            <Grid item md={12} lg={4}>
              <FaqHeaderContent
                bookingText={bookingText}
                setBookingText={setBookingText}
                setShowText={setShowText}
                showText={showText}
              />
            </Grid>
          ) : null}
          {(showText && window.innerWidth <= 600) || window.innerWidth > 600 ? (
            <Grid item md={12} lg={8}>
              <FaqAnswerContent
                bookingText={bookingText}
                expanded={expanded}
                setExpanded={setExpanded}
              />
            </Grid>
          ) : null}
        </Grid>
      </Grid>
    </>
  );
}
