/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */
import {
  Box,
  Card,
  Grid,
  Typography,
} from "@mui/material";
import { useState, useEffect, useRef } from "react";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import appColors from "../../Assets/AppTheme/appColors";
import appTheme from "../../Assets/AppTheme/appTheme";
import GiftCardDetailsDialog from "../../Components/DialogBoxes/GiftCardDialog/giftCardDetails";
import giftCardHeader from "../../Assets/newMobileIcon/GiftCards/giftCardBanner.svg";
import StyledInputField from "../../Components/TextFields/styledTextFile";
import QrScanDialog from "../../Components/DialogBoxes/qrScanDialog";
import { useDispatch } from "react-redux";
import {
  GetAvailableGiftCards,
  RedeemGiftCardAction,
} from "../../Redux/actions/giftCardAction";
import { ToastMsgState } from "../../Redux/actions/toastMsg";
import ToastMsgCotainer from "../../Components/ToastMsg/toastMsg";
import buyGiftCard from "../../Assets/newMobileIcon/GiftCards/buyGiftCard.png";
import myGiftCards from "../../Assets/newMobileIcon/GiftCards/myGiftCards.png";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import Lottie from "lottie-web";
import animate from "lottie-web";
export default function ClaimGiftCard() {
  const dispatch = useDispatch();
  const CouponAnimation = useRef(null);
  const [giftCardNumer, setGiftCardNumber] = useState("");
  const [openScanner, setOpenScanner] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [giftCardDetails, setGiftCardDetails] = useState({});
  const [giftcardSettings, setGiftcardSettings] = useState({});

  const [triggerGrapics, setTrigeerGrapics] = useState(false);
  const [error, setError] = useState("");
  const history = useHistory();
  //function to handel claim gift card
  const handelScannerResponse = (qrId) => {
    if (qrId.length) {
      handelRedeemAction(qrId);
    }
  };

  //function to handel qr dialog open
  const handelQrSearch = () => {
    setOpenScanner(true);
  };

  //function to handel api call for redeem
  const handelRedeemAction = (id) => {
    dispatch(
      RedeemGiftCardAction({
        giftCardId: id,
      })
    ).then((data) => {
      setOpenScanner(false);
      if (
        data?.data?.isPasswordCorrect === false ||
        data?.data?.isPasswordCorrect === true
      ) {
        setOpenDialog(true);
        setGiftCardDetails(data?.data);
        setGiftCardNumber("");
      } else if (data?.error) {
        setGiftCardDetails({});
        setGiftCardNumber("");
        dispatch(
          ToastMsgState({
            error: true,
            containerFlag: true,
            msg: data?.msg,
          })
        );
      } else {
        setGiftCardDetails({});
      }
    });
  };

  //function to handel proceed click
  const handelProceedClick = () => {
    handelRedeemAction(giftCardNumer);
  };

  //Function to handel gift card id and api hit
  useEffect(() => {
    if (giftCardNumer?.length === 16 && error === "") {
      handelProceedClick();
    }
  }, [giftCardNumer]);
  //function to handel buy gift card text onchange
  const handelEnterGiftCardId = (e) => {
    const inputValue = e.target.value.toUpperCase().replace(/-/g, "");
    const isBWGCFormat = inputValue.startsWith("BWGC");

    if (inputValue?.length <= 16) {
      setGiftCardNumber(inputValue);
    }
    if (isBWGCFormat) {
      setError("");
    } else {
      setError("Enter Valid Gift Card Id");
    }
  };

  //useeffect for lottie animation
  useEffect(() => {
    if (triggerGrapics) {
      Lottie.loadAnimation({
        container: CouponAnimation.current,
        renderer: "svg",
        loop: false,
        autoplay: true,
        animationData: require("../../Assets/Animations/CouponCodeAnimation.json"),
      });
      animate.setSpeed(0.6);
      setTrigeerGrapics(false);
      setTimeout(() => {
        animate.destroy();
      }, 3000);
    }
  }, [triggerGrapics]);

  //useeffect to trigger gift card settings
  useEffect(() => {
    dispatch(GetAvailableGiftCards()).then((data) => {
      if (data?.error === false) {
        setGiftcardSettings(data?.data?.gift_card_settings);
      }
    });
  }, []);

  console.log("giftcardSettings////////");
  console.log(giftcardSettings);

  return (
    <>
      <Grid
        container
        gap={0}
        direction={{ xs: "column", md: "row", position: "relative" }}
      >
        <div
          ref={CouponAnimation}
          style={{
            position: "absolute",
            top: 50,
            left: { xs: 0, md: 120 },
            textAlign: "center",
          }}
        />
        <Grid item xs={12} md={12}>
          <Box sx={claimGiftCardStyles.imageComponent}>
            <img
              alt="BookWater"
              src={giftCardHeader}
              style={claimGiftCardStyles.imageStyles}
            />
            <Typography sx={claimGiftCardStyles.typoGraphy1}>
              Gift Cards
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={claimGiftCardStyles.scanQrBox} onClick={handelQrSearch}>
            <p style={claimGiftCardStyles.clickToScan}>
              Click to scan <QrCodeScannerIcon />
            </p>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box sx={{ padding: "0 1rem", width: "100%" }}>
            <StyledInputField
              placeholder="Enter Gift Card 16 digit code"
              text="text"
              value={giftCardNumer}
              handelTextField={handelEnterGiftCardId}
              error={error}
            />
          </Box>
        </Grid>
        <Box sx={claimGiftCardStyles.spacingComponent}></Box>
        <Grid
          container
          sx={{ height: { md: "35vh", xs: 0 } }}
          alignContent={{ md: "center" }}
        >
          {giftcardSettings?.isEnable === true && (
            <Grid
              item
              xs={12}
              md={6}
              sx={{ marginTop: "1rem", padding: "0 1rem" }}
            >
              <Card
                sx={claimGiftCardStyles.cardStyles}
                onClick={() => history.push("/dashboard/buygiftcard")}
              >
                <Grid container>
                  <Grid
                    item
                    xs={8}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "start",
                      justifyContent: "space-around",
                    }}
                  >
                    <Typography
                      sx={[
                        claimGiftCardStyles.typoGraphy,
                        { marginLeft: ".5rem" },
                      ]}
                    >
                      Buy a gift card
                    </Typography>
                    <Typography
                      sx={[
                        claimGiftCardStyles.typoGraphy3,
                        { marginLeft: "1rem" },
                      ]}
                    >
                      Healthy Water, Healthy Bonds
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <img
                      src={buyGiftCard}
                      alt="BookWater"
                      style={{ height: "4rem" }}
                    />
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          )}
          <Grid
            item
            xs={12}
            md={6}
            sx={{ marginTop: "1rem", padding: "0 1rem" }}
          >
            <Card
              sx={claimGiftCardStyles.cardStyles}
              onClick={() => history.push("/dashboard/giftcardlist")}
            >
              <Grid container>
                <Grid item xs={4}>
                  <img
                    src={myGiftCards}
                    alt="BookWater"
                    style={{ height: "4rem" }}
                  />
                </Grid>
                <Grid
                  item
                  xs={8}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "end",
                    justifyContent: "space-around",
                  }}
                >
                  <Typography
                    sx={[
                      claimGiftCardStyles.typoGraphy,
                      { marginRight: ".5rem" },
                    ]}
                  >
                    My Gift Cards
                  </Typography>
                  <Typography
                    sx={[
                      claimGiftCardStyles.typoGraphy3,
                      { marginRight: "1rem" },
                    ]}
                  >
                    Manage Your Gift Cards
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Grid>
      {openScanner && (
        <QrScanDialog
          openScanner={openScanner}
          setOpenScanner={setOpenScanner}
          handelScannerResponse={handelScannerResponse}
        />
      )}
      <ToastMsgCotainer />

      {openDialog && (
        <GiftCardDetailsDialog
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
          giftCardDetails={giftCardDetails}
          setGiftCardDetails={setGiftCardDetails}
          setTrigeerGrapics={setTrigeerGrapics}
        />
      )}
    </>
  );
}

const claimGiftCardStyles = {
  imageComponent: {
    height: "20vh",
    width: { xs: "100vw", sm: "70vw", md: "100%" },
    overflow: "hidden",
    textAlign: "end",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "end",
    borderRadius: "1rem 1rem 0 0",
    boxShadow: "0 4px 2px -7px rgba(0, 0, 0, 0.2)",
    position: "relative",
    background:
      "linear-gradient(280deg, rgba(3,142,194,1) 10%, rgba(90,208,254,0) 100%)",
  },
  imageStyles: {
    height: "80%",
    objectFit: "contain",
  },
  typoGraphy: {
    fontSize: appTheme.fontSizexl,
    color: appColors.giftCardColor,
    fontWeight: "bold",
  },
  typoGraphy3: {
    fontSize: appTheme.fontSizeSmall,
    color: appColors.grayText,
    fontWeight: "bold",
  },
  typoGraphy1: {
    fontSize: appTheme.fontSizexxl,
    color: appColors.giftCardBlue,
    fontWeight: "bold",
    position: "absolute",
    bottom: 45,
    left: 15,
  },
  clickToScan: {
    background: appColors.giftCardColor,
    width: "10rem",
    borderRadius: "5rem",
    color: "#fff",
    fontWeight: "bold",
    padding: ".3rem",
  },
  scanQrBox: {
    display: "flex",
    justifyContent: "center",
    margin: ".5rem .5rem 0 .5rem",
  },
  spacingComponent: {
    borderBottom: "2px dashed gray",
    width: "100%",
    marginTop: "1rem",
  },
  cardStyles: {
    background: appColors.secondaryBlue,
    borderRadius: "1rem",
    padding: "1rem 0",
    "&:hover": {
      boxShadow: "0 4px 20px rgba(0, 0, 0, 0.2)",
    },
  },
};
