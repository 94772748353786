import React from "react";
import AuthTextField from "../TextFields/AuthTextFields";
import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import appMeta from "../../Assets/AppMeta/appMeta";
import AuthPasswordTextField from "../TextFields/AuthPasswordTextField";
import PrimaryButton from "../Buttons/PrimaryButton";
import appColors from "../../Assets/AppTheme/appColors";
import { useDispatch, useSelector } from "react-redux";
import appTheme from "../../Assets/AppTheme/appTheme";
import { Redirect } from "react-router-dom";
import { logIn } from "../../Redux/actions/ProfileActions";
import { toast } from "react-toastify";
import appMessages from "../../Assets/AppMeta/appMessages";

/**
 * @author Tahir Shaik
 * @description BookWater App Authentication Page With Login & SignUP Components
 * @date 01/06/23
 * @version 0.0.1 - Initial Release
 * @param {}
 * @returns {UI og Authetication Page with LogIn & SignUp Components}
 */
function Login(props) {
  const dispatch = useDispatch();
  const [emailPhone, setEmailPhone] = React.useState("");
  const auth = useSelector((state) => state.authentications);
  const [password, setPassword] = React.useState("");
  const [isRememberMe, setIsRememberMe] = React.useState(false);
  const [spinner, setSpinner] = React.useState(false);
  const [isLogin, setIsLogin] = React.useState(false);
  const [isEpValid, setIsEpValid] = React.useState(false);
  const [isPswdValid, setIsPswdValid] = React.useState(false);
  React.useEffect(() => {}, [emailPhone, password]);

  React.useEffect(() => {
    // console.log(auth);
    if (!auth.error) {
      setIsLogin(true);
      auth.error = true;
    }
  }, [auth, isLogin]);
  //For remeber me option

  /**
   * @author Tahir Shaik
   * @description Prform the Login
   * @date 07/06/23
   * @version 0.0.1 - Initial Release
   * @param {e ->events}
   * @returns {UI og Authetication Page with LogIn & SignUp Components}
   */
  const handleLogin = async (e) => {
    setSpinner(true);
    // Prevent form submission
    e.preventDefault();
    // Regular expressions for email and phone number validation
    const emailRegEx = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    const phoneRegEx = /^[5-9][0-9]{9}$/;
    if (phoneRegEx.test(emailPhone)) {
      dispatch(
        logIn({
          email_phone: emailPhone,
          password: password,
          storage_type: isRememberMe,
        })
      )
        .then((data) => {
          setSpinner(false);
        })
        .catch((error) => {
          setSpinner(false);
        });
    } else if (emailRegEx.test(emailPhone)) {
      dispatch(
        logIn({
          email_phone: emailPhone,
          password: password,
          storage_type: isRememberMe,
        })
      )
        .then((data) => {
          setSpinner(false);
        })
        .catch((error) => {
          setSpinner(false);
        });
    } else {
      setSpinner(false);
      toast.dark(appMessages.LogInInutsError, {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
    }
  };

  if (localStorage.getItem("token")) {
    return <Redirect to="/dashboard/home" />;
  }

  const handleClickForgetPassword = () => {
    props.setForgetPassword(true);
    props.setSubmitToVerify(false);
  };

  return (
    <Box sx={styles.mainContainer}>
      <AuthTextField
        lable={"Email/Phone"}
        type={appMeta.textFieldTypes.EMAIL_PHONE}
        setValue={setEmailPhone}
        value={emailPhone}
        isValid={isEpValid}
        setIsValid={setIsEpValid}
        isRequired={true}
        isHintRequired={false}
        isRememberMe={isRememberMe}
      />
      <AuthPasswordTextField
        lable={"Password"}
        type={appMeta.textFieldTypes.PASSWORD}
        isStrongPasswordRequired={false}
        setValue={setPassword}
        value={password}
        isValid={isPswdValid}
        setIsValid={setIsPswdValid}
        isRequired={true}
        isRememberMe={isRememberMe}
        isHintRequired={false}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Checkbox
          checked={isRememberMe}
          onClick={() => setIsRememberMe(!isRememberMe)}
          sx={{ color: isRememberMe ? appColors.white : "red" }}
          color="default"
        />{" "}
        <Typography>Remember Me</Typography>
      </Box>
      <PrimaryButton
        handleButtonClick={handleLogin}
        btnName="Login"
        spinner={spinner}
      />
      <Box sx={styles.forgotPasswordContainer}>
        <Typography
          sx={styles.forgotPassword}
          onClick={handleClickForgetPassword}
        >
          Forgot Password?
        </Typography>
      </Box>
    </Box>
  );
}

export default Login;

const styles = {
  mainContainer: {
    maxHeight: "100vh",
    marginTop: "10px",
    display: "flex",
    flexDirection: "column",
    gap: 1,
  },
  forgotPasswordContainer: {
    display: "flex",
    flexDirection: "row-reverse",
    width: "70%",
  },
  forgotPassword: {
    fontWeight: "bold",
    color: appColors.blue,
    fontSize: appTheme.textSize,
    "&:hover": {
      color: appColors.white,
    },
    cursor: "pointer",
  },
};
