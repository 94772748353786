/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Gowtham Prasath
 * @description
 * 
 */

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { legalStyles } from '../LegalInformation/legalStyles';
import appColors from '../../Assets/AppTheme/appColors';
import { useState,useEffect } from 'react';


export default function FaqAnswerContent({
    bookingText,
   expanded, 
   setExpanded
}) {

    //Function for handling accrodian opening
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };



    return (
        <div>
            {bookingText === "CONTACT" ?
                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                        sx={legalStyles.AccrodialStyle}
                    >
                        <Typography sx={{ width: '100%', flexShrink: 0 }}>
                            Contact
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails    sx={legalStyles.accrodDetails}>
                        <Typography sx={legalStyles.licenseDocuments1}>
                            We at BookWater would love to hear from our customers.
                        </Typography>
                        <Typography sx={legalStyles.licenseDocuments1}>
                            You may reach us through chat, Phone (+9180 695 92837 ) or e-mail (<a href="https://www.bookwater.com/contact/" target="_blank">
                                https://www.bookwater.com/contact/</a>)
                        </Typography>
                        <Typography sx={legalStyles.licenseDocuments}>Our India customer care number is
                            08069592837. You may also contact us at <a href="https://www.bookwater.com/contact/" target="_blank">
                                https://www.bookwater.com/contact/</a>

                        </Typography>
                    </AccordionDetails>
                </Accordion> : ""}


            {bookingText === "ABOUT" ?
                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                        sx={legalStyles.AccrodialStyle}
                    >
                        <Typography sx={{ width: '100%', flexShrink: 0 }}>
                            About
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={legalStyles.accrodDetails}>
                        <Typography sx={legalStyles.licenseDocuments1}>
                            BookWater is the world’s first IoT-powered, sustainable and quality-based water delivery
                            platform delivering safe and affordable water to all.
                            Refer to our Website <a href="https://www.bookwater.com" target="_blank">www.bookwater.com</a> for additional information.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                :
                ""
            }


            {bookingText === "CAREERS" ? <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    sx={legalStyles.AccrodialStyle}
                >
                    <Typography sx={{ width: '100%', flexShrink: 0 }}>
                        Careers
                    </Typography>
                </AccordionSummary>
                <AccordionDetails    sx={legalStyles.accrodDetails}>
                    <Typography sx={legalStyles.licenseDocuments1}>
                        We’re on the lookout for energetic and dynamic talent in the areas of sales, customer service,
                        IT, logistics, finance etc..
                    </Typography>
                    <Typography sx={legalStyles.licenseDocuments1}>
                        For employment opportunities at BookWater kindly contact us at <a href="https://www.bookwater.com/careers" target="_blank">www.bookwater.com/careers/</a>
                    </Typography>
                </AccordionDetails>
            </Accordion>
                :
                ""
            }




            {bookingText === "AREA OF SERVICE" ? <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    sx={legalStyles.AccrodialStyle}
                >
                    <Typography sx={{ width: '100%', flexShrink: 0 }}>
                        Serviceable Area
                    </Typography>
                </AccordionSummary>
                <AccordionDetails    sx={legalStyles.accrodDetails}>
                    <Typography sx={legalStyles.licenseDocuments1}>
                        Presently  BookWater  services the city of Chennai and its suburbs.
                        In the next few months, BookWater will be available in Bangalore and Hyderabad.
                    </Typography>
                    <Typography sx={legalStyles.licenseDocuments1}>
                        Over the next 12 months,BookWater will be operational in all major cities in India.
                        To check service in your area please enter the pincode in the app on registration or contact our customer care team.
                    </Typography>
                </AccordionDetails>
            </Accordion>
                :
                ""
            }



            {bookingText === "REGISTRATION AND BOOKING" ? <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    sx={legalStyles.AccrodialStyle}
                >
                    <Typography sx={{ width: '100%', flexShrink: 0 }}>
                        How can I book water
                    </Typography>
                </AccordionSummary>
                <AccordionDetails sx={legalStyles.accrodDetails}>
                    <Typography sx={legalStyles.licenseDocuments1}>
                        To ensure security and ease of use, all bookings are done online only. Kindly follow the following steps to book water.
                    </Typography>
                    <Typography sx={legalStyles.licenseDocuments1}>
                        If you are an Android user download the BookWater app from Playstore. Register yourself on the app. iPhone users can use our web app <a href="https://www.bookwater.com" target="_blank">www.bookwater.com</a>
                        and register yourself.
                    </Typography>
                    <Typography sx={{ width: '100%', fontWeight: 600, fontSize: ".9rem", flexShrink: 0, color: appColors.textColorNew }}>
                        Registration
                    </Typography>
                    <Typography sx={legalStyles.licenseDocuments1}>
                        Register yourself  using your name, mobile number, email id, and the address you want the water delivered to.
                        You may add multiple addresses to a single account.
                    </Typography>
                    <Typography sx={legalStyles.licenseDocuments1}>
                        Each phone number may be registered as a single user.
                        There can be multiple registrations through different phone numbers for a single address.
                    </Typography>
                    <Typography sx={{ width: '100%', flexShrink: 0, fontWeight: 600, fontSize: ".9rem", flexShrink: 0, color: appColors.textColorNew }}>
                        Water booking
                    </Typography>
                    <Typography sx={legalStyles.licenseDocuments1}>
                        Use the Mobile app or Web app to book water.
                        Choose the number of water cans needed and the address of delivery.
                        Choose when you would like the water to be delivered.
                        There are three delivery options based on when the water delivery is needed:
                        Regular, Same – Day and Subscription.
                    </Typography>
                    <Typography sx={legalStyles.licenseDocuments1}>
                        For urgent requirements, customers can opt for Same-day delivery.
                        This service will attract a nominal delivery fee of Rs. 15 per can.
                        There a minimum order Value for 2 cans for regular delivery and 1 can for Insta-Delivery.
                    </Typography>
                    <Typography sx={{ width: '100%', flexShrink: 0, fontWeight: 600, fontSize: ".9rem", flexShrink: 0, color: appColors.textColorNew }}>
                        Regular Order
                    </Typography>
                    <Typography sx={legalStyles.licenseDocuments1}>
                        Orders are placed a day in advance for delivery the next day.
                        E.g. if you wish the water to be delivered on 5th June place the order on 4th June.
                    </Typography>
                    <Typography sx={legalStyles.licenseDocuments1}>
                        NO Delivery Fee applicable for Regular Deliveries: Not applicable.
                    </Typography>
                    <Typography sx={legalStyles.licenseDocuments1}>
                        The minimum order for Regular Delivery is : 2 cans
                    </Typography>
                    <Typography sx={{ width: '100%', flexShrink: 0, fontWeight: 600, fontSize: ".9rem", flexShrink: 0, color: appColors.textColorNew }}>
                        Same-day delivery
                    </Typography>
                    <Typography sx={legalStyles.licenseDocuments1}>
                        Customers who want the water delivered urgently may opt for same-day delivery.
                        Orders can be placed for same-day delivery will be delivered within a few hours on the day of booking.
                        E.g. An order placed on 4th June will be on 4th June
                    </Typography>
                    <Typography sx={legalStyles.licenseDocuments1}>
                        Same-day delivery incurs a Delivery Fee of Rs. 15 per can
                    </Typography>
                    <Typography sx={legalStyles.licenseDocuments1}>
                        The minimum order for same-day delivery  is: 1 can
                    </Typography>
                    <Typography sx={{ width: '100%', flexShrink: 0, fontWeight: 600, fontSize: ".9rem", flexShrink: 0, color: appColors.textColorNew }}>
                        Subscription Order
                    </Typography>
                    <Typography sx={legalStyles.licenseDocuments1}>
                        Leave your water delivery schedule to us! Orders can be placed for a specific schedule in a week/month (e.g., 3 cans every Monday and Thursday).
                    </Typography>
                    <Typography sx={legalStyles.licenseDocuments1}>
                        No Delivery Fee is applicable for Subscription orders
                        The minimum order for Subscription is – 2 cans
                    </Typography>
                    <Typography sx={legalStyles.licenseDocuments1}>
                        To place a Subscription order, go to the Subscribe option on the main menu, choose the delivery address, the days of the week when the water is needed, and the start date for your Subscription.
                        Then pay the amount for the subscription.
                        Please note that payments for the Subscription are deducted from the water wallet so keep your water wallet loaded at all times with the adequate amount
                        Our delivery hours are between 6.00 am and 9.00 pm.
                    </Typography>
                    <Typography sx={{ width: '100%', flexShrink: 0, fontWeight: 600, fontSize: ".9rem", flexShrink: 0, color: appColors.textColorNew }}>
                        Payment
                    </Typography>
                    <Typography sx={legalStyles.licenseDocuments1}>
                        -We accept the following payment methods to book water: -
                    </Typography>
                    <Typography sx={legalStyles.licenseDocuments1}>
                        BookWater’s Water Wallet  The Water Wallet is a safe and easy way for payments.
                        For ease of payment, BookWater provides its customers the convenience of a Water Wallet.
                        Customers may load their Water Wallet with the desired amount and use the same to book water.
                        Any reimbursements for cancelled orders will be credited back to the customer’s water wallet.
                        This amount may be used for future orders.
                        Customers can request the amount to be reimbursed to their bank accounts if desired.
                        Load your water wallet with an adequate amount and enjoy hassle-free water booking.
                    </Typography>
                    <Typography sx={legalStyles.licenseDocuments1}>
                        - UPI Transfer
                    </Typography>
                    <Typography sx={legalStyles.licenseDocuments1}>
                        - Razor Pay through Credit or Debit Card
                    </Typography>
                    <Typography sx={legalStyles.licenseDocuments1}>
                        -  Net Banking
                    </Typography>
                    <Typography sx={legalStyles.licenseDocuments1}>
                        - Other Payment wallets
                    </Typography>
                </AccordionDetails>
            </Accordion>
                :
                ""
            }




            {bookingText === "REGISTRATION AND BOOKING" ? <Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    sx={legalStyles.AccrodialStyle}
                >
                    <Typography sx={{ width: '100%', flexShrink: 0 }}>
                        Bulk orders
                    </Typography>
                </AccordionSummary>
                <AccordionDetails    sx={legalStyles.accrodDetails}>
                    <Typography sx={legalStyles.licenseDocuments1}>
                        Customers may order in bulk for their organizations. Kindly write to
                        <a href="https://www.bookwater.com/contact/" target="_blank">
                            https://www.bookwater.com/contact/ </a>
                        regarding bulk orders pricing and delivery
                    </Typography>
                </AccordionDetails>
            </Accordion>
                :
                ""
            }




            {bookingText === "REGISTRATION AND BOOKING" ? <Accordion expanded={expanded === 'panel10'} onChange={handleChange('panel10')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    sx={legalStyles.AccrodialStyle}
                >
                    <Typography sx={{ width: '100%', flexShrink: 0 }}>
                        Cash on Delivery
                    </Typography>
                </AccordionSummary>
                <AccordionDetails    sx={legalStyles.accrodDetails}>
                    <Typography sx={legalStyles.licenseDocuments1}>
                        -We accept the following payment methods to book water: -
                    </Typography>
                    <Typography sx={legalStyles.licenseDocuments1}>
                        Since all bookings and payments are online, the Cash on Delivery option IS NOT available.
                        All bookings must be paid for in advance through an online payment method.
                    </Typography>
                    <Typography sx={legalStyles.licenseDocuments1}>
                        For large orders please connect to us at customer care for additional information and assistance
                    </Typography>
                </AccordionDetails>
            </Accordion>
                :
                ""
            }

            {bookingText === "REGISTRATION AND BOOKING" ? <Accordion expanded={expanded === 'panel11'} onChange={handleChange('panel11')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    sx={legalStyles.AccrodialStyle}
                >
                    <Typography sx={{ width: '100%', flexShrink: 0 }}>
                        UPI QR Code/UPI not Working
                    </Typography>
                </AccordionSummary>
                <AccordionDetails    sx={legalStyles.accrodDetails}>
                    <Typography sx={legalStyles.licenseDocuments1}>
                        Kindly check your internet connection and UPI ID and re-try. If the issue persists,
                        contact Customer Care through the chat option in the BookWater app or
                    </Typography>
                    <Typography sx={legalStyles.licenseDocuments1}>
                        <a href="https://www.bookwater.com/contact/" target="_blank">https://www.bookwater.com/contact/</a>
                        You may also call us at 08069592837 to speak to
                        our customer care executive.
                    </Typography>
                    <Typography sx={legalStyles.licenseDocuments1}>
                        For large orders please connect to us at customer care for additional information and assistance
                    </Typography>
                </AccordionDetails>
            </Accordion>
                :
                ""
            }


            {bookingText === "REGISTRATION AND BOOKING" ? <Accordion expanded={expanded === 'panel12'} onChange={handleChange('panel12')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    sx={legalStyles.AccrodialStyle}
                >
                    <Typography sx={{ width: '100%', flexShrink: 0 }}>
                        Not able to add money to BookWater wallet
                    </Typography>
                </AccordionSummary>
                <AccordionDetails    sx={legalStyles.accrodDetails}>
                    <Typography sx={legalStyles.licenseDocuments1}>
                        If you are not able to add money into your Water Wallet with the current payment method, please try an alternate payment method.
                    </Typography>
                    <Typography sx={legalStyles.licenseDocuments1}>
                        If the issue persists, contact Customer Care through the chat option in the BookWater app or
                        <a href="https://www.bookwater.com/contact/" target="_blank">https://www.bookwater.com/contact/</a>
                        You may also call us at 08069592837 to speak to our customer care executive.
                    </Typography>
                </AccordionDetails>
            </Accordion>
                :
                ""
            }

            {bookingText === "REGISTRATION AND BOOKING" ? <Accordion expanded={expanded === 'panel13'} onChange={handleChange('panel13')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    sx={legalStyles.AccrodialStyle}
                >
                    <Typography sx={{ width: '100%', flexShrink: 0 }}>
                        Debit or Credit card safety
                    </Typography>
                </AccordionSummary>
                <AccordionDetails    sx={legalStyles.accrodDetails}>
                    <Typography sx={legalStyles.licenseDocuments1}>
                        All card transactions are secured through RazorPay and are completely safe.
                    </Typography>
                </AccordionDetails>
            </Accordion>
                :
                ""
            }





            {bookingText === "QR CODE" ? <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    sx={legalStyles.AccrodialStyle}
                >
                    <Typography sx={{ width: '100%', flexShrink: 0 }}>
                        What is the QR code on the water can
                    </Typography>
                </AccordionSummary>
                <AccordionDetails    sx={legalStyles.accrodDetails}>
                    <Typography sx={legalStyles.licenseDocuments1}>
                        Every water can is equipped with 2 QR codes.
                        One directs you to the BookWater website while the other QR code towards the lower end of the can is a wealth of information regarding the quality of the can and the water it contains.
                    </Typography>
                    <Typography sx={legalStyles.licenseDocuments1}>
                        At BookWater we believe in quality and transparency of information.
                        Scanning the QR code gives information on when the can was packed, the expiry date for the can, the number of times the can has been reused, and the complete Water Test Report for the water in that particular can.
                    </Typography>
                    <Typography sx={legalStyles.licenseDocuments1}>
                        The QR code gives the following information:-
                        TDS levels as per government guidelines
                        pH of the water
                        Can usage count
                        Now you know your can and know your water too!
                    </Typography>
                    <Typography sx={legalStyles.licenseDocuments1}>
                        Can Usage
                        Microplastics can cause irreparable damage to the human body.
                        Overused plastic cans leach microplastics into the water and lead to serious ailments.
                        A can at BookWater is reused only 30 times after which it is upcycled into fibre and made into T-Shirts.
                        Organizations who share our passion for the environment may order T-Shirts made from upcycled cans.
                    </Typography>
                </AccordionDetails>
            </Accordion>
                :
                ""
            }


            {bookingText === "Significance of OTP for delivery" ? <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    sx={legalStyles.AccrodialStyle}
                >
                    <Typography sx={{ width: '100%', flexShrink: 0 }}>
                        Significance of OTP for delivery
                    </Typography>
                </AccordionSummary>
                <AccordionDetails    sx={legalStyles.accrodDetails}>
                    <Typography sx={legalStyles.licenseDocuments1}>
                        BookWater cans are CONNECTED CANS meaning, each can be tracked through its life of 30 refill cycles.
                        The QR Code consists of the information about the water and the can,
                        and the OTP links the can to a specific customer location.
                        The OTP ensures the customer that the can is a genuine one from BookWater designated for his specific use.
                        Because of the CONNECTED can system,
                        it is impossible for any spurious or counterfeit products to enter the BookWater ecosystem
                    </Typography>
                </AccordionDetails>
            </Accordion>
                :
                ""
            }



            {bookingText === "QUALITY" ? <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    sx={legalStyles.AccrodialStyle}
                >
                    <Typography sx={{ width: '100%', flexShrink: 0 }}>
                        How can I know the quality of the water in the bottle?
                    </Typography>
                </AccordionSummary>
                <AccordionDetails    sx={legalStyles.accrodDetails}>
                    <Typography sx={legalStyles.licenseDocuments1}>
                        Scanning the QR code gives the complete Water Quality Report for the water in the
                        bottle.
                    </Typography>
                </AccordionDetails>
            </Accordion>
                :
                ""
            }


            {bookingText === "QUALITY" ? <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    sx={legalStyles.AccrodialStyle}
                >
                    <Typography sx={{ width: '100%', flexShrink: 0 }}>
                        How many times is a can reused? / What is our Environment policy?
                    </Typography>
                </AccordionSummary>
                <AccordionDetails    sx={legalStyles.accrodDetails}>
                    <Typography sx={legalStyles.licenseDocuments1}>
                        A can at BookWater is reused only 30 times after which it is upcycled into fibre and
                        made into T-Shirts. Organizations who share our passion for the environment may
                        order T-Shirts made from upcycled cans.
                    </Typography>
                </AccordionDetails>
            </Accordion>
                :
                ""
            }

            {bookingText === "REFUND POLICY" ? <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    sx={legalStyles.AccrodialStyle}
                >
                    <Typography sx={{ width: '100%', flexShrink: 0 }}>
                        What is the refund policy? / How can I get a refund?
                    </Typography>
                </AccordionSummary>
                <AccordionDetails    sx={legalStyles.accrodDetails}>
                    <Typography sx={legalStyles.licenseDocuments1}>
                        customers may Any refunds for canceled orders / wrong payments etc. will be credited back to the customer’s water wallet. This amount may be used for future
                        orders. Customers can request the amount to be reimbursed to their bank accounts if desired.
                    </Typography>
                </AccordionDetails>
            </Accordion>
                :
                ""
            }

            {bookingText === "CANCELLATION" ? <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    sx={legalStyles.AccrodialStyle}
                >
                    <Typography sx={{ width: '100%', flexShrink: 0 }}>
                        How can I cancel my order?
                    </Typography>
                </AccordionSummary>
                <AccordionDetails    sx={legalStyles.accrodDetails}>
                    <Typography sx={legalStyles.licenseDocuments1}>
                        An order can be canceled the previous day for a regular order and 3 hours in advance for an insta-delivery order.
                        Canceling may be done through the BookWater app.
                        The refunds for canceled orders will be credited back to the customer’s water wallet.
                        This amount may be used for future orders.
                    </Typography>
                </AccordionDetails>
            </Accordion>
                :
                ""
            }


            {bookingText === "CANCELLATION" ? <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    sx={legalStyles.AccrodialStyle}
                >
                    <Typography sx={{ width: '100%', flexShrink: 0 }}>
                        Subscription Cancellation
                    </Typography>
                </AccordionSummary>
                <AccordionDetails    sx={legalStyles.accrodDetails}>
                    <Typography sx={legalStyles.licenseDocuments1}>
                        To cancel a Subscription, go the Subscribe option on the app and Pause or Delete the Subscription.
                        The refunds for cancelled orders will be credited back to the customer’s water wallet.
                        This amount may be used for future orders.
                    </Typography>
                    <Typography sx={legalStyles.licenseDocuments1}>
                        Customers can cancel their orders three hours prior to delivery.
                        Any refunds for cancelled orders / wrong payments etc. will be credited back to the customer’s water wallet.
                        This amount may be used for future orders.
                        Customers can request the amount to be reimbursed to their bank accounts if desired
                    </Typography>
                </AccordionDetails>
            </Accordion>
                :
                ""
            }



            {bookingText === "CANCELLATION" ? <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    sx={legalStyles.AccrodialStyle}
                >
                    <Typography sx={{ width: '100%', flexShrink: 0 }}>
                        How can I Deactivate my account with BookWater?
                    </Typography>
                </AccordionSummary>
                <AccordionDetails    sx={legalStyles.accrodDetails}>
                    <Typography sx={legalStyles.licenseDocuments1}>
                        Following are the steps to deactivate a BookWater account.
                        The customer may request for deactivation of account thorough email
                        (support@bookwater.com) or through chat in the BookWater app.
                    </Typography>
                    <Typography sx={legalStyles.licenseDocuments1}>
                        Through the app, the customer can request for return of cans in their possession.
                        The empty cans will be picked up and inspected at our facility. Post inspection,
                        the deposit for the cans will be credited to the customer’s water wallet.
                        The refund for the amount in the water wallet will be then initiated.
                        As per bank payment rules, the refund will be credited to the customer’s bank account within
                        7 working days.
                        All refunds will be made through bank transfer only.
                    </Typography>
                </AccordionDetails>
            </Accordion>
                :
                ""
            }

            {bookingText === "DELIVERY" ? <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    sx={legalStyles.AccrodialStyle}
                >
                    <Typography sx={{ width: '100%', flexShrink: 0 }}>
                        Where does BookWater deliver water?
                    </Typography>
                </AccordionSummary>
                <AccordionDetails    sx={legalStyles.accrodDetails}>
                    <Typography sx={legalStyles.licenseDocuments1}>
                        As of now, BookWater delivers in the city of Chennai and its suburbs.
                        In the next few months, we will begin operations in other cities too.
                        Over the next 12 months, BookWater will be operational in all major cities in India.
                        To find out if your area is serviceable,
                        kindly check the PIN Code of your area in the BookWater app.
                    </Typography>
                </AccordionDetails>
            </Accordion>
                :
                ""
            }


            {bookingText === "DELIVERY" ? <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    sx={legalStyles.AccrodialStyle}
                >
                    <Typography sx={{ width: '100%', flexShrink: 0 }}>
                        How many water bottles can I order?
                    </Typography>
                </AccordionSummary>
                <AccordionDetails    sx={legalStyles.accrodDetails}>
                    <Typography sx={legalStyles.licenseDocuments1}>
                        For a Standard delivery, the minimum order is 2 bottles.
                        For an Insta-Delivery customers may order even a single bottle.
                    </Typography>
                </AccordionDetails>
            </Accordion>
                :
                ""
            }


            {bookingText === "DELIVERY" ? <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    sx={legalStyles.AccrodialStyle}
                >
                    <Typography sx={{ width: '100%', flexShrink: 0 }}>
                        Can I add more than 1 Delivery address to an account?
                    </Typography>
                </AccordionSummary>
                <AccordionDetails    sx={legalStyles.accrodDetails}>
                    <Typography sx={legalStyles.licenseDocuments1}>
                        Yes, an account may have multiple delivery addresses.
                        Customers should take care to choose the right address while booking an order.
                    </Typography>
                </AccordionDetails>
            </Accordion>
                :
                ""
            }

            {bookingText === "DELIVERY" ? <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    sx={legalStyles.AccrodialStyle}
                >
                    <Typography sx={{ width: '100%', flexShrink: 0 }}>
                        Do you Charge for Delivery?
                    </Typography>
                </AccordionSummary>
                <AccordionDetails    sx={legalStyles.accrodDetails}>
                    <Typography sx={legalStyles.licenseDocuments1}>
                        Regular deliveries are free.
                        Is it possible for same day delivery: BookWater has introduced Insta-Delivery for same day water delivery
                        within a few hours. The delivery charges for Insta-Delivery are Rs. 15 per water can. E.g.
                        if a customer wants 3 water cans to be delivered urgently he will be paying Rs. 45 as delivery
                        charges with the regular water charges.
                    </Typography>
                </AccordionDetails>
            </Accordion>
                :
                ""
            }


            {bookingText === "DELIVERY" ? <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    sx={legalStyles.AccrodialStyle}
                >
                    <Typography sx={{ width: '100%', flexShrink: 0 }}>
                        How long do you take to deliver?
                    </Typography>
                </AccordionSummary>
                <AccordionDetails    sx={legalStyles.accrodDetails}>
                    <Typography sx={legalStyles.licenseDocuments1}>
                        One day for Regular booking and a few hours for Insta-Booking. For advance (previous-day)
                        bookings Customers can see their delivery slots on the BookWater App.
                    </Typography>
                </AccordionDetails>
            </Accordion>
                :
                ""
            }



            {bookingText === "DELIVERY" ? <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    sx={legalStyles.AccrodialStyle}
                >
                    <Typography sx={{ width: '100%', flexShrink: 0 }}>
                        What are your delivery hours?
                    </Typography>
                </AccordionSummary>
                <AccordionDetails    sx={legalStyles.accrodDetails}>
                    <Typography sx={legalStyles.licenseDocuments1}>
                        Our delivery hours are between 6.00 am and 9.00 pm.
                    </Typography>
                </AccordionDetails>
            </Accordion>
                :
                ""
            }

            {bookingText === "DELIVERY" ? <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    sx={legalStyles.AccrodialStyle}
                >
                    <Typography sx={{ width: '100%', flexShrink: 0 }}>
                        Can I have multiple Registrations in the same address?
                    </Typography>
                </AccordionSummary>
                <AccordionDetails    sx={legalStyles.accrodDetails}>
                    <Typography sx={legalStyles.licenseDocuments1}>
                        Each phone number may be registered as a single user.
                        There can be multiple registrations through different phone numbers for a single address.
                    </Typography>
                </AccordionDetails>
            </Accordion>
                :
                ""
            }




            {bookingText === "REFER & EARN" ?
                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                        sx={legalStyles.AccrodialStyle}
                    >
                        <Typography sx={{ width: '100%', flexShrink: 0 }}>
                            REFER & EARN ?
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails    sx={legalStyles.accrodDetails}>
                        <Typography sx={legalStyles.licenseDocuments1}>
                            Now customers can refer to their friends and family to the goodness of BookWater.
                            Every account has its own Unique Referral Code. Share your Referral code with others to register on BookWater.
                            You receive credits in the form of 55 Water Drops in your wallet and they do too!
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                :
                ""
            }

            {bookingText === "BOOKWATER PROMOTIONS" ?
                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                        sx={legalStyles.AccrodialStyle}
                    >
                        <Typography sx={{ width: '100%', flexShrink: 0 }}>
                            BOOKWATER PROMOTIONS?
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails    sx={legalStyles.accrodDetails}>
                        <Typography sx={legalStyles.licenseDocuments1}>
                            BookWater offers regular promotions and offers for its customers. Do check the PROMO CODES when you book water next.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                :
                ""
            }



            {bookingText === "BookWater Account Deactivation" ?
                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                        sx={legalStyles.AccrodialStyle}
                    >
                        <Typography sx={{ width: '100%', flexShrink: 0 }}>
                            Deactivation
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails    sx={legalStyles.accrodDetails}>
                        <Typography sx={legalStyles.licenseDocuments1}>
                            Following are the steps to deactivate a BookWater account.
                        </Typography>
                        <Typography sx={legalStyles.licenseDocuments1}>
                            The customer may request account deactivation through email
                            <a href="https://support@bookwater.com" target="_blank">support@bookwater.com</a> or through chat on the BookWater app.
                        </Typography>
                        <Typography sx={legalStyles.licenseDocuments1}>
                            The existing cans have to be taken back and inspected before the can deposit is returned to the customer.
                        </Typography>
                        <Typography sx={legalStyles.licenseDocuments1}>
                            Through the app, the customer can place a request to return the cans in their possession.
                            The empty cans will be picked up and inspected at our facility.
                            Post inspection, the deposit for the cans will be credited to the customer’s water wallet.
                        </Typography>
                        <Typography sx={legalStyles.licenseDocuments1}>
                            The refund for the amount in the water wallet into the customer’s bank account will then be initiated.
                            As per bank payment rules, the refund will be credited to the customer’s bank account within 7 working days.
                            All refunds will be made through bank transfer only.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                :
                ""
            }


            {bookingText === "OUR PRODUCTS" ?
                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                        sx={legalStyles.AccrodialStyle}
                    >
                        <Typography sx={{ width: '100%', flexShrink: 0 }}>
                            Products
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails    sx={legalStyles.accrodDetails}>
                        <Typography sx={legalStyles.licenseDocuments1}>
                            We currently supply high-quality 20L Packaged Drinking Water in BookWater’s
                            CONNECTED CANS. In our continued commitment to the environment,
                            our upcoming offering includes 350ml and 750ml glass water bottles.
                        </Typography>
                        <Typography sx={legalStyles.licenseDocuments1}>
                            The company also plans to introduce Mineral Water and Alkaline Water to its water portfolio in the coming months.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                :
                ""
            }
        </div>
    );
}