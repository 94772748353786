/**
 *  @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Tahir Shaik
 * @description Custom Button Field (Buuton Field)
 * @date 02/06/23
 * @version 0.0.1 - Initial Release
 */

// Importing the Libraries and other Components
import { Button, CircularProgress } from "@mui/material";
import React from "react";
import appColors from "../../Assets/AppTheme/appColors";
import appTheme from "../../Assets/AppTheme/appTheme";
import { useSelector } from "react-redux";
/**
 * @author Tahir Shaik
 * @description Custom Password Text Field (Inputs- password)
 * @date 06/06/23
 * @version 0.0.1 - Initial Release
 * @param { lable, type, setValue, value,isValid, setIsValid, isRequired, isHintRequired, isStrongPasswordRequired}
 * @returns {UI or Authetication Page with LogIn & SignUp Components}
 */
function PrimaryButton(props) {
  const styles = {
    btnStyle: {
      backgroundColor: appColors.textColorNew,
      color: appColors.white,
      height: props.btnName === "Contact Support" ? "30px" : "40px",
      width: "150px",
      borderRadius: "0px 10px 0px 10px",
      textTransform: "none",
      fontWeight: "bold",
      fontSize: appTheme.buttonTextSize,
      "&:hover": { backgroundColor: appColors.blue },
      marginTop: "3%",
    },
  };
  const authenticationDetails = useSelector((state) => state.authReducer);
  return (
    <Button
      sx={styles.btnStyle}
      onClick={props.handleButtonClick}
      disabled={props.loading}
    >
      {props.spinner ? (
        <CircularProgress size={24} color="inherit" />
      ) : (
        props.btnName
      )}
    </Button>
  );
}

export default PrimaryButton;
