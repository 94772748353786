/**
 * @author Gowtham Prasath
 * @description Promo Code Apply and Viewing Dialog Box
 * @copyright Bookwater tech pvt ltd
 */
import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import { useDispatch, useSelector } from "react-redux";
import { Box, Card, CardContent, Typography } from "@mui/material";
import "../../../Pages/Common/drawer.css";
import "./successBanner.css";
import IconButton from "@mui/material/IconButton";
import { CardActionArea } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { dialogStyles } from "./newPromoDialogCss";
import { ApplyPromocode } from "../../../Redux/actions/promocodesAction";
import appColors from "../../../Assets/AppTheme/appColors";

export default function PromocodesNewDialog({
  openPromoDialog,
  setOpenPromoDialog,
  twtyLtrWaterPrice,
  bookingFormObj,
  setPromoApplied,
  setPromoId,
  searchPromoCodes,
  setPromoFlag,
  setHandePromoFlag,
  setpromoCode,
}) {
  const [promoSearch, setPromoSearch] = React.useState("");
  const [promoSearchFlag, setPromoSearchFlag] = React.useState(false);
  const checkOutDetails = useSelector(
    (state) => state.checkOutDetails.checkOutDetails
  );
  const dispatch = useDispatch();
  const promocodeData = useSelector((state) => state.promocodeDetails);
  // const referAndEarnDetails = useSelector((state) => state.referAndEarnDetails);
  //Function for closing Dialog Box
  const handleClose = () => {
    setOpenPromoDialog(false);
  };

  const getPromoId = (data) => {
    if (checkOutDetails?.final_price !== undefined) {
      const reqObj = {
        final_water_price: checkOutDetails?.final_price,
        can_count: checkOutDetails?.valid_products.length,
        is_wallet: false,
        // isRemovePromo: true,
      };
      setPromoApplied(true);
      setpromoCode(data.promo_label);
      setPromoFlag(true);
      setPromoId(data.promo_master_id);
      setHandePromoFlag(false);
      handleClose();
    } else {
      setpromoCode(data.promo_label);
      setPromoApplied(true);
      setPromoFlag(true);
      setPromoId(data.promo_master_id);
      setHandePromoFlag(false);
      handleClose();
    }
  };

  const handlePromoChange = (e) => {
    setPromoSearch(e.target.value);
  };

  const handleClickCoupon = () => {
    setPromoSearchFlag(true);
  };

  React.useEffect(() => {
    if (promoSearchFlag) {
      searchPromoCodes(promoSearch);
      setPromoSearchFlag(false);
    } else if (promoSearch === "") {
      searchPromoCodes(promoSearch);
      setPromoSearchFlag(false);
    }
  }, [promoSearchFlag, promoSearch, twtyLtrWaterPrice]);

  return (
    <>
      <Dialog
        open={openPromoDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={dialogStyles.dialog}
        PaperProps={{
          sx: {
            borderRadius: "20px", // Set border radius for curved effect
          },
        }}
      >
        <DialogContent sx={dialogStyles.dialogContent}>
          <Box sx={dialogStyles.closeIcon} onClick={handleClose}>
            <CloseIcon sx={{ fontSize: "2rem", color: "#fff" }} />
          </Box>
          <Box sx={dialogStyles.textFieldBox}>
            <Typography sx={dialogStyles.typograpy1}>
              Coupon Code & Offers
            </Typography>
            <TextField
              id="simple-textfield"
              placeholder="Enter Coupon Code"
              size="small"
              variant="outlined"
              sx={dialogStyles.textfield}
              type="search"
              value={promoSearch}
              onChange={handlePromoChange}
              InputProps={{
                endAdornment: (
                  <IconButton onClick={handleClickCoupon}>
                    <span style={{ fontSize: ".8rem", color: "#08d5c9" }}>
                      APPLY
                    </span>
                  </IconButton>
                ),
              }}
            />
            <Box sx={dialogStyles.scrollPromo}>
              {promocodeData.error === false ? (
                <Box>
                  {promocodeData.Promocodedata.map((data, index) => {
                    return (
                      <Card
                        sx={[dialogStyles.mainCard, { marginTop: ".5rem" }]}
                        key={index}
                      >
                        <CardActionArea
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            boxShadow: 4,
                          }}
                        >
                          <CardContent
                            sx={[
                              dialogStyles.cardContent,
                              {
                                width: "100%",
                                backgroundColor:
                                  data.promoDisabled === true
                                    ? "#58595b"
                                    : "#30a6ff",
                              },
                            ]}
                          >
                            <Typography> {data.promo_label}</Typography>
                            {data.promoDisabled === true ? (
                              ""
                            ) : (
                              <Button
                                sx={{ color: "white", margin: -1 }}
                                size="small"
                                onClick={() => getPromoId(data)}
                              >
                                APPLY
                              </Button>
                            )}
                          </CardContent>
                          <CardContent
                            style={{
                              minWidth: "100%",
                              overflow: "hidden",
                              // border: "1px solid gray",
                            }}
                          >
                            <Typography variant="body2" color="text.secondary">
                              Save up to {data.promo_offer_value} with this
                              code.
                            </Typography>
                            <Typography>
                              Disclaimer: {data.promo_disclaimer}
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    );
                  })}
                </Box>
              ) : (
                <Typography
                  sx={{
                    textAlign: "center",
                    fontWeight: "bold",
                    padding: "10px",
                    color: appColors.textColorNew,
                  }}
                >
                  No promo codes Available
                </Typography>
              )}
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
