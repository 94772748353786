/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */
import { appBarClasses } from "@mui/material";
import appColors from "../../../Assets/AppTheme/appColors";
import appTheme from "../../../Assets/AppTheme/appTheme";

export const dialogStyles = {
  dialog: {
    backgroundColor: appColors.dialogBackGround,
    vw: "30rem",
    vh: "33rem",
  },
  dialogContent: {
    backgroundColor: appColors.homePageCard,
  },
  dialogContent1: {
    backgroundColor: appColors.cardColor,
    borderRadius: ".5rem",
    marginTop: ".5rem",
    border: "1px solid #038ec2",
  },
  closeIcon: {
    position: "absolute",
    top: -1,
    right: 0,
    backgroundColor: appColors.textColorNew,
    borderRadius: ".3rem",
    cursor: "pointer",
  },
  textFieldBox: {
    display: "flex",
    flexDirection: "column",
    color: "white",
    padding: ".5rem",
    borderRadius:".5rem"
  },
  typograpy1: {
    color: appColors.textColorNew,
    fontSize: appTheme.fontSizeMedium,
    fontWeight: "bold",
    fontStyle: "normal",
  },
  typograpy2: {
    fontSize: ".6rem",
    marginBottom: ".2rem",
    color: appColors.Textgray,
  },
  textfield: {
    backgroundColor: "white",
    width: "100%",
    height: "40px",
    marginTop: "1rem",
  },
  mainCard: {
    marginBottom: ".8rem",
    // marginTop:"1rem",
    borderRadius: "10px",
  },
  cardContent: {
    borderTopRadius: "15px",
    // width: "100%",
    color: "white",
    display: "flex",
    // border: "1px solid gray",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  scrollPromo: {
    maxHeight: "30rem",
    overflowY: "scroll",
    height: "100%",
    "&::-webkit-scrollbar": {
      width: "0.2em",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#fff",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#30a7ff" /* Set the background color of the thumb */,
    },
  },
  scrollPromoCard: {
    maxHeight: "18rem",
    borderRadius: ".5rem",
    overflowY: "scroll",
    height: "100%",
    "&::-webkit-scrollbar": {
      width: "0.2em",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#fff",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#30a7ff" /* Set the background color of the thumb */,
    },
  },

  ///OTP Styles are Written below

  otpDialog: {
    position: "absolute",
    top: -1,
    right: 0,
    backgroundColor: "white",
    borderRadius: "1rem",
    cursor: "pointer",
    backgroundColor: "#fff",
    color: appColors.textColorNew,
  },
  otpTypograpy1: {
    fontSize: "1.5rem",
    fontFamily: "OpenSans",
    fontStyle: "normal",
    fontWeight: "bold",
    color: "#30a7ff",
  },
  otpTypograpy2: {
    fontSize: "2rem",
    fontFamily: "OpenSans",
    fontStyle: "normal",
    fontWeight: "bold",
    color: "#152057",
    textAlign: "center",
  },
  activeBox: {
    backgroundColor: "#cce3ff",
    padding: " .3rem",
    margin: 0,
    marginBottom: ".3rem",
    borderRadius: ".5rem",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  otpTypograpy3: {
    margin: ".3rem",
    fontWeight: "bold",
    color: "#58595b",
  },
  bookingidOtp: {
    backgroundColor: "#30a6ff",
    width: "1.5rem",
    height: "1.5rem",
    borderRadius: "1rem",
  },
  bookingId: {
    color: appColors.darkGray,
    fontSize: ".9rem",
    marginBottom: ".3rem",
    fontStyle: "normal",
  },
  deliveryDate: {
    color: "#58595b",
    fontSize: ".9rem",
    fontStyle: "normal",
  },
};
