/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */
import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import WaterWalletIcon from "../../../../Assets/newMobileIcon/Booking/Waterwallet - Colour 1.svg";
import IconListManager from "../../../../Assets/AppTheme/appIcons";
import UPISvg from "../../../../Assets/AppIcons/PGUPI.svg";
import razorPay from "../../../../Assets/newMobileIcon/Booking/Cards.svg";
import scanQr from "../../../../Assets/newMobileIcon/Booking/qr-code-scan.svg";
import { Box } from "@mui/material";
import appColors from "../../../../Assets/AppTheme/appColors";
import { useDispatch, useSelector } from "react-redux";
import { EditOrderActionSku } from "../../../../Redux/actions/skuOrderPlacingAction";
import {
  ClearDepositDetailAction,
  DepositRefundDetailsAction,
  ExtraDepositAction,
} from "../../../../Redux/actions/DepositDetailsAction";
import "./deposit.css";
import { LoadingButton } from "@mui/lab";
import SelectAddressDetails from "../../../../Pages/Reports/selectAddressDialog";
import { ToastMsgState } from "../../../../Redux/actions/toastMsg";
import appTheme from "../../../../Assets/AppTheme/appTheme";
import appMessages from "../../../../Assets/AppMeta/appMessages";
import { toast } from "react-toastify";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function ExtraDepositDialog({
  open,
  onClose,
  data,
  setPaymentDialogOpen,
  setOrderId,
  setBookingPaymentFlag,
  paymentType,
  setEnteredPayAmount,
  setpaymentFlag,
  payType,
  isUpiIdValid,
  upiUserOption,
  amountEntered,
}) {
  const depositData = useSelector((state) => state.depositData);
  const dispatch = useDispatch();
  const [orderQty, setOrderQty] = React.useState(1);
  const [capacity, setCapacity] = React.useState("");
  const [material, setMaterial] = React.useState("");
  const [depositAmount, setDepositAmount] = React.useState(0);
  const [Addressid, setAddressId] = React.useState("");
  //function for handelclose dialog
  const handleClose = () => {
    onClose();
    dispatch(ClearDepositDetailAction());
    setDepositAmount(0);
  };

  //function for paying deposit
  const handelPayForDeposit = () => {
    setBookingPaymentFlag(true);
    onClose();
    setDepositAmount(0);
  };

  //function for handel reduse quantity
  const handelOnchangeTextField = (e) => {
    const inputValue = e.target.value;
    if (/^[1-9]\d*$/.test(inputValue) && parseInt(inputValue) > 0) {
      setOrderQty(inputValue);
      setDepositAmount(0);
    } else {
      setOrderQty("");
    }
  };

  //useeffect for setting orderquantity
  React.useEffect(() => {
    setCapacity(data?.container_capacity);
    setMaterial(data?.container_material);
  }, [data]);

  //function for handeling submit
  const handelSubmitExtraDeposit = () => {
    if (Addressid !== "" && orderQty !== "" && orderQty !== 0) {
      const reqObj = {
        capacity: capacity,
        for_order: "",
        order_qty: 0,
        material: material,
        quantity: orderQty,
        is_crate: data?.is_crate,
        address_id: Addressid,
        payment_method: paymentType,
        return_qty: 0,
      };
      dispatch(ExtraDepositAction(reqObj));
    } else {
      toast.error(appMessages.depositAddressError, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  //UseEffect for setting payment details
  React.useEffect(() => {
    if (
      !depositData.extraDepositError &&
      depositData.extraDepositError !== undefined
    ) {
      setDepositAmount(depositData?.extraDeposiData?.amount);
      setOrderId(depositData?.extraDeposiData?.order_id);
      setEnteredPayAmount(depositData?.extraDeposiData?.amount);
      depositData.extraDepositError = true;
    }
  }, [depositData.extraDepositError]);

  //useEffect to hit deposit api
  React.useEffect(() => {
    if (Addressid !== "") {
      handelSubmitExtraDeposit();
    }
  }, [Addressid]);
  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        PaperProps={{
          sx: {
            borderRadius: "20px", // Set border radius for curved effect
          },
        }}
      >
        <DialogTitle
          sx={{
            m: 0,
            p: 2,
            color: appColors.textColorNew,
            backgroundColor: appColors.secondaryCard,
          }}
          id="customized-dialog-title"
        >
          Add Deposit
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent
          dividers
          sx={{
            overflow: "hidden",
            height: "100%",
            backgroundColor: appColors.secondaryCard,
          }}
        >
          <Box
            sx={{ display: "flex", flexDirection: "column", minWidth: "15rem" }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "flex-start",
              }}
            >
              <Box
                sx={{
                  color: appColors.darkGray,
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Typography>Enter Deposit Quantity </Typography>
                <Typography
                  sx={{
                    fontSize: appTheme.fontSizeMedium,
                    color: appColors.textColorNew,
                  }}
                >
                  {" "}
                  {parseFloat(depositData?.extraDeposiData?.amount) > 0
                    ? `₹ ${depositData?.extraDeposiData?.amount}`
                    : ""}
                </Typography>
              </Box>
              <Box sx={{ width: "100%" }}>
                <input
                  type="text"
                  placeholder="Enter Quantity"
                  className="input"
                  style={{ width: "100%" }}
                  value={orderQty}
                  onChange={handelOnchangeTextField}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "flex-start",
                marginBottom: "1rem",
              }}
            >
              <Typography
                sx={{ color: appColors.darkGray, marginBottom: "1rem" }}
              >
                Select Address
              </Typography>
              <SelectAddressDetails
                Addressid={Addressid}
                setAddressId={setAddressId}
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-around",
            backgroundColor: appColors.secondaryCard,
          }}
        >
          <Button
            variant="contained"
            size="small"
            onClick={handleClose}
            sx={{ backgroundColor: appColors.Failed }}
          >
            Close
          </Button>
          {depositAmount === 0 || depositData?.extraDeposiData === undefined ? (
            <LoadingButton
              variant="contained"
              size="small"
              onClick={handelSubmitExtraDeposit}
              loading={depositData.loading}
            >
              Calculate Price
            </LoadingButton>
          ) : (
            <Button
              variant="contained"
              size="small"
              onClick={handelPayForDeposit}
              loading={depositData.loading}
            >
              Proceed
            </Button>
          )}
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}
