/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author
 * @description Booking.jsx is the parent file for the booking page
 * @date 06/06/23
 * @version 0.0.8 - Initial Release
 */
import * as React from "react";
import Button from "@mui/material/Button";
import Slide from "@mui/material/Slide";
import closeIcon from "../../../Assets/newMobileIcon/Booking/closeIconWhite.svg";
import infoIcon from "../../../Assets/newMobileIcon/Booking/info-circle-white.svg";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import { Box, TextField } from "@mui/material";
import appColors from "../../../Assets/AppTheme/appColors";
import actionTypes from "../../../Redux/actions/actionTypes";
import { DeleteAccountAction } from "../../../Redux/actions/profileAction";
import {
  BrowserRouter as Router,
  Redirect,
  useHistory,
} from "react-router-dom";
import { deleteAccountStyles } from "./DeleteAccountStyles";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DeleteAccountDialog(props) {
  const dispatch = useDispatch();
  const [acceptCondition, setAcceptCondition] = React.useState(false);
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [phoneNumberError, setPhoneNumberError] = React.useState(false);
  const [isLogOut, setIsLogOut] = React.useState(false);

  //For closing dialog box
  const handleClose = () => {
    props.setOpenDeleteDialog(false);
    setAcceptCondition(false);
    setPhoneNumber("");
  };

  //function for handling delete account action
  const handelDeleteAccount = () => {
    if (!acceptCondition) {
      setAcceptCondition(true);
    } else {
      if (props.profileDetails.user.phone === phoneNumber) {
        dispatch(DeleteAccountAction());
        dispatch({
          type: actionTypes.SIGN_OUT,
        });
        setIsLogOut(true);
      } else {
        if (phoneNumber === "") {
          toast.dark("Please Enter Phone Number", {
            position: "bottom-right",
            autoClose: 3000,
            type: "error",
          });
        } else {
          toast.dark("Entered phone number does not match with your profile", {
            position: "bottom-right",
            autoClose: 3000,
            type: "error",
          });
        }
      }
    }
  };

  //function for handling phone number function
  const handelPhoneNo = (event) => {
    let reg = /^[6-9][0-9]{9}$/;
    var result = reg.test(event.target.value);
    setPhoneNumberError(!result);
    setPhoneNumber(event.target.value);
  };

  //condition for redirecting to loginpage
  if (isLogOut) {
    return <Redirect to="authenticate" />;
  }
  return (
    <div>
      <Dialog
        open={props.openDeleteDialog}
        onClose={handleClose}
        TransitionComponent={Transition}
        sx={{ backgroundColor: appColors.dialogBackGround }}
        aria-labelledby="responsive-dialog-title"
        PaperProps={{
          sx: {
            borderRadius: "20px", // Set border radius for curved effect
          },
        }}
      >
        <Box sx={{ backgroundColor: appColors.walletCard }}>
          {!acceptCondition ? (
            <>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "1rem",
                }}
              >
                <img
                  src={infoIcon}
                  alt=""
                  style={{ height: "50px", width: "50px" }}
                />
              </Box>
              <Typography sx={deleteAccountStyles.stepOneTypo}>
                Account Termination Disclaimer
              </Typography>
              <Typography sx={deleteAccountStyles.stepOneSubTypo}>
                Account termination is irreversible and all associated data will
                be permanently deleted including your wallet data / water drops.
              </Typography>
              <Typography sx={deleteAccountStyles.stepOneSubTypo}>
                Before requesting for deletion, we encourage you to exhaust /
                submit a ticket to request for refund of any remaining wallet
                balance. Failure to do so will result in forfeiture of your
                wallet amount.
              </Typography>{" "}
              <Typography sx={deleteAccountStyles.stepOneSubTypo}>
                Water drops cannot be redeemed for cash and will expire upon
                account deletion
              </Typography>
              <Typography sx={deleteAccountStyles.stepOneSubTypo}>
                Are you sure you want to delete your account?
              </Typography>
            </>
          ) : (
            <Box sx={{ padding: "1rem", width: "19rem" }}>
              {" "}
              <Typography sx={deleteAccountStyles.stepOneTypo}>
                 Account Deletetion Confirmation
              </Typography>
              <Typography sx={deleteAccountStyles.stepTwoSubTypo}>
                The Verification of your phone number is required to confirm
                your identity and proceed with account termination.
              </Typography>
              <TextField
                size="small"
                type="number"
                label="Phone Number"
                value={phoneNumber}
                error={phoneNumberError}
                variant="filled"
                color="primary"
                focused
                onChange={handelPhoneNo}
                sx={{
                  backgroundColor: "white",
                  fontSize: "18px",
                  borderRadius: "5px",
                  width: "100%",
                  marginTop: "2%",
                }}
              />
              <Typography sx={deleteAccountStyles.stepTwoSubTypo}>
                By verifying your phone number, you consent to the termination
                of your account and accept the associated terms and conditions
              </Typography>
            </Box>
          )}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-evenly",
              marginBottom: "1rem",
            }}
          >
            <Button
              sx={[
                deleteAccountStyles.acceptButton,
                {
                  backgroundColor: acceptCondition
                    ? appColors.Failed
                    : appColors.walletCard,
                  "&:hover": {
                    color: appColors.white,
                    backgroundColor: acceptCondition
                      ? appColors.Failed
                      : appColors.walletCard,
                  },
                },
              ]}
              varient="contained"
              size="small"
              onClick={handelDeleteAccount}
            >
              {acceptCondition ? "Verify" : "Proceed"}
            </Button>
            &nbsp;
            <Button
              sx={{
                color: appColors.white,
                bgcolor: appColors.walletCard,
                boxShadow: 5,
              }}
              varient="contained"
              size="small"
              onClick={handleClose}
            >
              Cancel
            </Button>
          </Box>
          <img
            alt=""
            src={closeIcon}
            style={deleteAccountStyles.closeIcon}
            onClick={handleClose}
          />
        </Box>
      </Dialog>
    </div>
  );
}
