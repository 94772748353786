import React from "react";

export default function BackIcon({
    stroke
}) {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.25 12.5C24.25 18.9893 18.9893 24.25 12.5 24.25C6.01065 24.25 0.75 18.9893 0.75 12.5C0.75 6.01065 6.01065 0.75 12.5 0.75C18.9893 0.75 24.25 6.01065 24.25 12.5Z"
        stroke={stroke}
        stroke-width="1.5"
      />
      <path
        d="M6.03449 11.7929C5.64397 12.1834 5.64397 12.8166 6.03449 13.2071L12.3985 19.5711C12.789 19.9616 13.4221 19.9616 13.8127 19.5711C14.2032 19.1805 14.2032 18.5474 13.8127 18.1569L8.15581 12.5L13.8127 6.84315C14.2032 6.45262 14.2032 5.81946 13.8127 5.42893C13.4221 5.03841 12.789 5.03841 12.3985 5.42893L6.03449 11.7929ZM18.2585 11.5L6.7416 11.5V13.5L18.2585 13.5V11.5Z"
        fill={stroke}
      />
    </svg>
  );
}
