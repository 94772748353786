/**
 *  @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Gowtham Prasth
 * @description Custom Button Field (Buuton Field)
 * @date 06/12/24
 * @version 0.0.1 - Initial Release
 */
import appColors from "../../Assets/AppTheme/appColors";
import { Box } from "@mui/material";

export default function GiftCardPayOptions({
  minimumValue,
  maximumValue,
  handelClick,
  selectedValue,
}) {
  const createGiftCardAmountOptions = (
    minimumAmount = 0,
    maximumAmount = 0
  ) => {
    // Validate input
    if (
      minimumAmount === null ||
      maximumAmount === null ||
      minimumAmount > maximumAmount
    ) {
      return null;
    }

    if (minimumAmount === maximumAmount) {
      return [maximumAmount];
    }

    // Initialize options with min and max values
    let options = [minimumAmount, maximumAmount];

    // Ensure range is wide enough for 5 values, otherwise return min and max
    if (maximumAmount - minimumAmount < 4 || minimumAmount < 1) {
      return options;
    }

    // Initialize options array with placeholders for 5 values
    options = [minimumAmount, 0, 0, 0, maximumAmount];

    // Define step sizes
    const stepSizes = [100, 50, 25, 10, 5, 1];
    const range = maximumAmount - minimumAmount;
    const step = stepSizes.find((size) => range / size >= 3);

    // Calculate middle values
    const middleValues = Array.from({ length: 3 }, (_, i) => {
      const value = minimumAmount + (i + 1) * (range / 4);
      return Math.round(value / step) * step;
    });

    // Insert middle values into the options array
    options[1] = middleValues[0];
    options[2] = middleValues[1];
    options[3] = middleValues[2];

    return options;
  };

  const options = createGiftCardAmountOptions(minimumValue, maximumValue);

  return (
    <Box
      sx={{
        display: "flex",
        overflowX: "auto",
        gap: "1rem",
        padding: "0.5rem 0",
        "&::-webkit-scrollbar": {
          display: "none",
        },
      }}
    >
      {options?.map((option) => (
        <button
          key={option}
          style={{
            borderRadius: "8px",
            border: "1px solid #ccc",
            background:
              selectedValue === option
                ? appColors.lightblue
                : appColors.cardColor,
            color:
              selectedValue === option
                ? appColors.grayText
                : appColors.darkGray,
            cursor: "pointer",
            fontSize: ".8rem",
            fontWeight: "bold",
            padding: ".4rem",
          }}
          onClick={() => handelClick(option)}
        >
          ₹{option.toFixed(0)}
        </button>
      ))}
    </Box>
  );
}
