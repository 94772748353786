import React from "react";

export default function ReferandEarn({ stroke }) {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_2152_3013"
        maskType="luminance"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="30"
        height="30"
      >
        <path d="M0.5 0.5H29.0872V29.5H0.5V0.5Z" fill="white" stroke="white" />
      </mask>
      <g mask="url(#mask0_2152_3013)">
        <path
          d="M10.4602 7.08977C10.4603 7.43615 10.3931 7.77917 10.2625 8.09922C10.1318 8.41927 9.94029 8.71009 9.69878 8.95506C9.45727 9.20003 9.17053 9.39436 8.85494 9.52695C8.53934 9.65953 8.20107 9.72777 7.85946 9.72777C7.51784 9.72777 7.17958 9.65953 6.86398 9.52695C6.54838 9.39436 6.26164 9.20003 6.02013 8.95506C5.77861 8.71009 5.58707 8.41927 5.45643 8.09922C5.32579 7.77917 5.25862 7.43615 5.25875 7.08977C5.25526 6.38347 5.52618 5.70417 6.0129 5.19887C6.49962 4.69357 7.16298 4.4029 7.85946 4.38977C8.55593 4.4029 9.2193 4.69357 9.70601 5.19887C10.1927 5.70417 10.4637 6.38347 10.4602 7.08977Z"
          stroke={stroke}
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path
          d="M4.02486 13.8279C4.39298 13.1202 4.94467 12.5279 5.62041 12.1149C6.29616 11.7018 7.0703 11.4836 7.85935 11.4839C8.6484 11.4836 9.42255 11.7018 10.0983 12.1149C10.774 12.5279 11.3257 13.1202 11.6938 13.8279"
          stroke={stroke}
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path
          d="M14.7946 7.96878C14.7946 9.83352 14.064 11.6219 12.7636 12.9404C11.4632 14.259 9.69942 14.9998 7.86035 14.9998C6.01525 15.0019 4.24427 14.2637 2.93415 12.9464C1.62403 11.629 0.881206 9.83958 0.86792 7.96878C0.87879 6.0918 1.61899 4.29483 2.92798 2.96757C4.23697 1.64032 6.00919 0.889805 7.86035 0.878784C9.7054 0.892256 11.4703 1.64543 12.7695 2.97383C14.0687 4.30223 14.7967 6.09793 14.7946 7.96878Z"
          stroke={stroke}
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path
          d="M24.3296 21.153C24.3296 21.8524 24.0556 22.5231 23.5678 23.0176C23.0801 23.5122 22.4186 23.79 21.7289 23.79C21.0391 23.79 20.3776 23.5122 19.8899 23.0176C19.4022 22.5231 19.1282 21.8524 19.1282 21.153C19.1282 20.4536 19.4022 19.7829 19.8899 19.2883C20.3776 18.7938 21.0391 18.516 21.7289 18.516C22.4186 18.516 23.0801 18.7938 23.5678 19.2883C24.0556 19.7829 24.3296 20.4536 24.3296 21.153Z"
          stroke={stroke}
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path
          d="M17.8934 27.9499C18.2586 27.2356 18.8082 26.6351 19.4834 26.2124C20.1587 25.7898 20.9344 25.5608 21.7279 25.5499C22.5214 25.5608 23.2971 25.7898 23.9724 26.2124C24.6477 26.6351 25.1972 27.2356 25.5624 27.9499"
          stroke={stroke}
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path
          d="M28.7213 22.032C28.7104 23.909 27.9702 25.706 26.6612 27.0332C25.3522 28.3605 23.58 29.111 21.7289 29.122C19.8838 29.1085 18.1189 28.3554 16.8197 27.027C15.5205 25.6985 14.7925 23.9028 14.7946 22.032C14.7945 21.1085 14.9738 20.194 15.3222 19.3408C15.6707 18.4876 16.1816 17.7123 16.8256 17.0593C17.4696 16.4063 18.2342 15.8884 19.0757 15.535C19.9172 15.1817 20.8191 14.9999 21.7298 15C23.5749 14.9981 25.3458 15.7366 26.6557 17.0541C27.9657 18.3716 28.7083 20.1612 28.7213 22.032Z"
          stroke={stroke}
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path
          d="M4.39173 17.6367V20.2737C4.3896 22.1446 5.11764 23.9403 6.41686 25.2687C7.71608 26.5971 9.48092 27.3502 11.326 27.3637H13.9267"
          stroke={stroke}
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path
          d="M1.791 20.2737L4.39171 17.6367L6.99242 20.2737"
          stroke={stroke}
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path
          d="M26.0625 12.364V9.72696C26.0646 7.85611 25.3366 6.06041 24.0373 4.73201C22.7381 3.40361 20.9733 2.65043 19.1282 2.63696H16.5275"
          stroke={stroke}
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path
          d="M28.6632 9.72705L26.0625 12.364L23.4618 9.72705"
          stroke={stroke}
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
      </g>
    </svg>
  );
}
