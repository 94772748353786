/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Gowtham Prasath
 * @description Custom Text Field (Password Field)
 * @date 01/12/23
 * @version 0.0.1 - Initial Release
 */

import React from "react";
import { Box, Card, Grid, Typography } from "@mui/material";
import appColors from "../../../Assets/AppTheme/appColors";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import RequiredButton from "../../SkuButtons/SkuRequiredButton";
import ReturnButton from "../../SkuButtons/SKUReturnButton";
import EditIcon from "../../../Assets/newMobileIcon/menu/editicon.svg";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch, useSelector } from "react-redux";
import {
  AddProductQuantity,
  AddReturnQuantity,
  DeleteAllProductQty,
  DeleteProductaction,
  GetProductsByAddress,
  ReduseProductQuantity,
  ReduseReturnQuantity,
  SelectedProductaction,
  AddCrateQuantity,
  ReduseCrateQuantity,
} from "../../../Redux/actions/skuBookingAction";
import moment from "moment";
import useProductImages from "../../Utils/productImages";
import ImageLoader from "../../Utils/imageLoader";
import iIcon from "../../../Assets/newMobileIcon/Booking/iIcon.svg";
import IconListManager from "../../../Assets/AppTheme/appIcons";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { LoadingButton } from "@mui/lab";
import cart from "../../../Assets/newMobileIcon/home/PurchaseCan.svg";
import drops from "../../../Assets/newMobileIcon/home/Waterfill.svg";
import { ToastMsgState } from "../../../Redux/actions/toastMsg";
import { bookingCards } from "./cardStyles";
import appTheme from "../../../Assets/AppTheme/appTheme";
import PendingOrderDialogBox from "../../DialogBoxes/BookNow/pendingOrderDialog";
import { AllOrderOtpPaymentDetails } from "../../../Redux/actions/recentActivityAction";
import { CancelOrderError } from "../../../Redux/actions/ordersAction";
import CustomConsole from "../../../coustomConsole";
import failedOrder from "../../../Assets/newMobileIcon/Booking/Order Failed.svg";
import crateAvailableImage from "../../../Assets/newMobileIcon/menu/crate_available.svg";
import CrateSelectionBottomSheet from "../../DialogBoxes/SkuDialogs/DashBoard/bottomsheet";
import ProductInfoDialog from "../../DialogBoxes/SkuDialogs/DashBoard/productInfoDialog";
import CrateInfoDialog from "../../DialogBoxes/SkuDialogs/DashBoard/crateInfoDialog";
// import { crateDetailsJson } from "../../Utils/testJson";
export default function BookingCardsSku({
  toggleDrawer,
  setCheckOutUi,
  currentDate,
  infoCard,
  setInfoCard,
  selectedProduct,
  setSelectedProduct,
}) {
  const iconList = IconListManager();
  const dispatch = useDispatch();
  const checkOutDetails = useSelector((state) => state.checkOutDetails);
  const paymentPendingOrder = useSelector((state) => state.recentActivities);
  const orderDetails = useSelector((state) => state.ordersDetails);
  // const productsWithImages = useProductImages(data);
  const { pathname, search } = useLocation();
  const pageName = pathname.split("/dashboard/");
  const productDetails = useSelector(
    (state) => state.skuBookDetails.productDetails
  );
  const paymentBlocked = productDetails?.admin_settings?.payment_methods.map(
    (method) => method.type
  );
  const SkuProductDetails = useSelector((state) => state.skuBookDetails);
  const [pendingFlag, setPendingFlag] = React.useState(false);
  const BlocketPayments = new Set(paymentBlocked);
  //settingsAction
  const [returnCanFlags, setReturnCanFlags] = React.useState({});
  const [returnCanFlag, setreturnCanFlag] = React.useState(false);
  const [loadingStates, setLoadingStates] = React.useState({});
  const [productsData, setProdctsData] = React.useState([]);
  const [isOrderInPaymentPending, setIsOrderPaymentPending] =
    React.useState(false);

  const [products, setProducts] = React.useState({
    products: [],
    crates: [],
    booking_type: 1,
    address_id: SkuProductDetails?.selectedAddressId,
    coupon_code: "",
    water_drops: 0,
    payment_method: "PAYSHARP",
    is_otp_less: true,
    delivery_date: currentDate,
    is_auto_apply_water_drops: false,
    is_auto_apply_coupon: true,
    secondary_payment_method: "",
  });

  const [cardValue, setCardValue] = React.useState(1);
  const [paymentPendingOrderId, setpaymentPendingOrderId] = React.useState("");
  const [isOneTimeProduct, setIsOneTimeProduct] = React.useState(false);
  const [crateDetailsJson, setCrateDetailsJson] = React.useState([]);
  const [crateInfoOpen, setCrateInfoOpen] = React.useState(false);
  //Useeffect for fetching data from required apis
  React.useEffect(() => {
    if (pageName[0] !== "/mobile" && pageName[1] !== "subscribe") {
      dispatch(AllOrderOtpPaymentDetails());
    }
  }, []);

  React.useEffect(() => {
    // console.log("----Crate json---");
    // console.log(crateDetailsJson);
  }, [crateDetailsJson]);

  const [openCrateSelectionDialog, setOpenCrateSelectionDialog] =
    React.useState(false);
  //useEffect for handling payment pending order id
  React.useEffect(() => {
    if (
      checkOutDetails?.checkOutData !== undefined &&
      checkOutDetails?.checkOutData?.order_id !== undefined &&
      checkOutDetails?.checkOutData?.order_id !== "" &&
      pageName[1] !== "subscribe"
    ) {
      setpaymentPendingOrderId(checkOutDetails?.checkOutData?.order_id);
    } else if (
      paymentPendingOrder?.allDetails?.payment_pending?.length > 0 &&
      pageName[1] !== "subscribe"
    ) {
      setpaymentPendingOrderId(
        paymentPendingOrder?.allDetails?.payment_pending[0].order_id
      );
    }
  }, [paymentPendingOrder?.allDetails, checkOutDetails?.checkOutData]);

  //Function for handiling product adding details
  const handelAddProducts = (data) => {
    // handelLoadingAddButton(data);
    if (!pendingFlag) {
      dispatch(AddProductQuantity(data));
      if (cardValue === 1) {
        dispatch(AddReturnQuantity(data));
      }
      setCheckOutUi(true);
    } else if (pendingFlag) {
      setIsOrderPaymentPending(true);
    }
    setSelectedProduct(data);
    // setOpenCrateSelectionDialog(true);
    //--------//
  };

  //function to handel reduce crate quantity
  const handleAddCrate = (data) => {
    if (!pendingFlag) {
      dispatch(AddCrateQuantity(data));
      setCheckOutUi(true);
    } else if (pendingFlag) {
      setIsOrderPaymentPending(true);
    }
    setSelectedProduct(data);
  };

  //function to handel reusecrate quantity
  const handelReduseCrateQuantity = (data) => {
    if (!pendingFlag) {
      dispatch(ReduseCrateQuantity(data));
    }
    setSelectedProduct(data);
  };
  //HandelAddQuantity for adding quantity
  const handelAddQuantity = (data) => {
    console.log("Add quantity data...............");
    console.log(data);
    if (
      data?.quantity <
        parseInt(data?.filled_stock_with_distributor / data?.sku_qty[0]) ||
      pageName[1] === "subscribe"
    ) {
      dispatch(AddProductQuantity(data, cardValue));
    } else {
      dispatch(
        ToastMsgState({
          error: true,
          containerFlag: true,
          msg: `Distributor not having enough stock`,
        })
      );
    }
  };

  //function for handling reduse quantityaction
  const handelReduseQuantity = (data) => {
    dispatch(ReduseProductQuantity(data, cardValue));
  };
  console.log("skuproductdetials...............");
  console.log(productDetails);

  //function for handling Add Return quantityaction
  const handelAddReturnQuantity = (data) => {
    CustomConsole(data?.return);
    if (data?.return !== undefined) {
      dispatch(AddReturnQuantity(data, cardValue));
    } else if (data?.return === undefined || data?.return === 0) {
      dispatch(
        ToastMsgState({
          error: true,
          containerFlag: true,
          msg: `Not having enough stock to return`,
        })
      );
    }
  };

  //function for handling Reduse Return quantityaction
  const handelReduseReturnQuantity = (data) => {
    dispatch(ReduseReturnQuantity(data, cardValue));
  };

  //function for opening handel returnbutton
  const handelOpenReturnButton = (data) => {
    setreturnCanFlag(!returnCanFlag);
    setReturnCanFlags((prevFlags) => ({
      ...prevFlags,
      [data.id]: !prevFlags[data.id],
    }));
  };

  React.useEffect(() => {
    //Product details logic from the reducer

    console.log("-----Update Obj---");
    console.log(productDetails);

    const updatedProducts = productDetails?.products
      ?.map((item) => {
        // Check if quantity is defined and has changed
        if (
          item?.quantity !== undefined &&
          item?.min_book_qty <= item?.quantity
        ) {
          // Create the product object
          const product = {
            quantity: item?.quantity || 0,
            return: item?.return || 0,
            sku_qty: item?.sku_qty[0] || 0,
            booking_type: item?.booking_type || 0,
            capacity: item?.capacity,
            material: item?.material,
          };

          // Conditionally add the crates key if crateDetails is defined
          if (item?.crateDetails !== undefined) {
            updatedProducts.crates = item?.crateDetails;
          }

          return product;
        }
        return undefined; // Return undefined for items that don't meet the condition
      })
      .filter(Boolean);

    //for crates

    const updatedCrates = productDetails?.crates
      ?.map((item) => {
        // Check if quantity is defined and has changed
        if (item?.quantity !== undefined && item?.quantity > 0) {
          // Create the product object
          const crate = {
            quantity: item?.quantity || 0,
            return: item?.return || 0,
            sku_qty: 1,
            booking_type: item?.booking_type || 0,
            capacity: item?.capacity,
            material: item?.name,
          };

          // Conditionally add the crates key if crateDetails is defined
          // if (item?.crateDetails !== undefined) {
          //   updatedProducts.crates = item?.crateDetails;
          // }

          return crate;
        }
        return undefined; // Return undefined for items that don't meet the condition
      })
      .filter(Boolean);

    console.log("-----Update Obj 2---");
    console.log(updatedCrates);

    setProducts((prevProducts) => ({
      ...prevProducts,
      booking_type: cardValue,
      products: updatedProducts,
      crates: updatedCrates,
    }));
  }, [productDetails]);

  //function for updating product details
  React.useEffect(() => {
    if (products?.products?.length >= 0 || products?.crates?.length >= 0) {
      console.log("----selected product triggered---");
      console.log(products);
      dispatch(SelectedProductaction(products));
    }

    // if(products?.crates?.length >= 0){
    //   dispatch(SelectedProductaction(products));
    // }

    console.log("-----final object to send----");
    console.log(products);
  }, [products]);

  //function for handling info icon
  const handelInfoIcon = (data, product) => {
    console.log("info icon data");
    console.log(data);
    if (product === "water") {
      setSelectedProduct(data);
      // setOpenCrateSelectionDialog(true);
      setInfoCard(true);
    } else if (product === "crate") {
      setSelectedProduct(data);
      setCrateInfoOpen(true);
    }
  };

  //function for deleting product
  const handelDeleteProduct = (data) => {
    dispatch(DeleteProductaction(data));
  };

  //useEffect for updating product details
  React.useEffect(() => {
    setProducts((prevProducts) => ({
      ...prevProducts,
      address_id: SkuProductDetails?.selectedAddressId,
      is_auto_apply_water_drops:
        SkuProductDetails?.productDetails?.water_drop_details?.auto_pay,
    }));
  }, [SkuProductDetails?.productDetails]);

  const handelLoadingAddButton = (data) => {
    setLoadingStates((prevState) => ({ ...prevState, [data.id]: true }));
    setTimeout(() => {
      setLoadingStates((prevState) => ({ ...prevState, [data.id]: false }));
    }, 2000);
  };

  //function for handling oneTime and refill sale
  const handelRefillOnetimeSale = (data) => {
    setCardValue(data);
    dispatch(DeleteAllProductQty());
    setProducts((prevProducts) => ({
      ...prevProducts,
      booking_type: data,
    }));
  };

  //useEffect for setting product details based on card value
  React.useEffect(() => {
    console.log("----Products----");
    console.log();

    if (
      SkuProductDetails &&
      SkuProductDetails.productDetails &&
      pageName[1] !== "subscribe"
    ) {
      const crateProducts = SkuProductDetails?.productDetails?.crates?.filter(
        (data) => data?.id !== null
      );
      setCrateDetailsJson(crateProducts);
    }

    if (cardValue === 1) {
      const refillProducts =
        SkuProductDetails?.productDetails?.products?.filter(
          (data) => data?.booking_type === 1 || data?.booking_type === 3
        );
      setProdctsData(refillProducts);
    } else if (cardValue === 2) {
      const oneTimeProducts =
        SkuProductDetails?.productDetails?.products?.filter(
          (data) => data?.booking_type === 2 || data?.booking_type === 3
        );
      setProdctsData(oneTimeProducts);
    }
  }, [SkuProductDetails?.productDetails, cardValue]);

  //useEffect for getting onrtime sale button changes
  React.useEffect(() => {
    const oneTimeProducts = SkuProductDetails?.productDetails?.products?.find(
      (data) => data?.booking_type === 2 || data?.booking_type === 3
    );
    setIsOneTimeProduct(oneTimeProducts);
  }, [SkuProductDetails?.productDetails]);

  //function to get max return
  function getMaxReturn(containerCapacity, containerMaterial) {
    const userStock =
      SkuProductDetails?.productDetails?.userStockData?.user_stock;
    if (userStock !== undefined) {
      for (const item of userStock) {
        if (
          item.container_capacity === containerCapacity &&
          item.container_material === containerMaterial
        ) {
          return item.max_return;
        }
      }
    }
    return 0; // Default return value if no match found
  }

  // //useEffect for setting up an payment menthod
  React.useEffect(() => {
    let validPayment = "";
    if (pageName[1] !== "subscribe") {
      if (!BlocketPayments.has(300)) {
        validPayment = "WALLET";
      } else if (
        (!BlocketPayments.has(100) && !BlocketPayments.has(101)) ||
        (!BlocketPayments.has(100) && !BlocketPayments.has(102)) ||
        (!BlocketPayments.has(100) && !BlocketPayments.has(103))
      ) {
        validPayment = "PAYSHARP";
      } else if (!BlocketPayments.has(200)) {
        validPayment = "RAZORPAY";
      }
    } else {
      if (
        (!BlocketPayments.has(100) && !BlocketPayments.has(101)) ||
        (!BlocketPayments.has(100) && !BlocketPayments.has(102)) ||
        (!BlocketPayments.has(100) && !BlocketPayments.has(103))
      ) {
        validPayment = "PAYSHARP";
      } else if (!BlocketPayments.has(200)) {
        validPayment = "RAZORPAY";
      } else if (!BlocketPayments.has(300)) {
        validPayment = "WALLET";
      }
    }
    setProducts((prevProducts) => ({
      ...prevProducts,
      payment_method: validPayment,
    }));
  }, [productDetails]);

  //useEffect for adding current date if user selected
  React.useEffect(() => {
    // Your product details logic from the reducer
    setProducts((prevProducts) => ({
      ...prevProducts,
      delivery_date: moment(currentDate).format("YYYY-MM-DD"),
    }));
  }, [currentDate]);

  //Function for handling payment pending order dialogbox
  const handlePaymentPendingDialog = () => {};

  //Useeffect for payment pending order
  React.useEffect(() => {
    if (pageName[1] !== "subscribe") {
      if (paymentPendingOrder?.allDetails?.payment_pending?.length !== 0) {
        let isPendingOrder =
          paymentPendingOrder?.allDetails?.payment_pending?.some(
            (item, idx) => item.payment_type !== 300
          );
        CustomConsole(isPendingOrder);
        setPendingFlag(isPendingOrder);
      } else if (
        paymentPendingOrder?.allDetails?.payment_pending?.length === 0
      ) {
        setPendingFlag(false);
      }
    }
  }, [paymentPendingOrder?.allDetails]);

  //useeffect for getting cancel order details
  React.useEffect(() => {
    if (!orderDetails.cancelOrderError) {
      dispatch(AllOrderOtpPaymentDetails());
      dispatch(CancelOrderError());
      dispatch(
        GetProductsByAddress({
          address_id: SkuProductDetails.selectedAddressId,
          validate_dist_stock: true,
        })
      );
    }
  }, [orderDetails.cancelOrderError]);

  //useeffect for handling reorder details
  React.useEffect(() => {
    if (
      checkOutDetails?.checkOutData !== undefined &&
      checkOutDetails?.checkOutData?.order_id !== undefined &&
      checkOutDetails?.checkOutData?.order_id !== "" &&
      pageName[1] !== "subscribe"
    ) {
      setIsOrderPaymentPending(true);
    }
  }, [checkOutDetails?.checkOutData]);

  console.log("..products...............");
  console.log(products);

  // hook to call coutom image download hook
  const { images, isLoading, error } = useProductImages(
    SkuProductDetails?.productDetails?.products,
    "booking"
  );
  // hook to call coutom image download hook
  // useProductImages(SkuProductDetails?.productDetails?.crates, "booking");

  console.log("crateDetailsJson");
  console.log(crateDetailsJson);
  return (
    <>
      <Box sx={bookingCards.saleTypeCard}>
        <Card
          sx={{
            padding: ".5rem",
            width: { xs: "100%", sm: "11rem" },
            borderRadius: appTheme.fontSizeLarge,
            boxShadow: 2,
            color:
              cardValue === 1
                ? appColors.textColorNew
                : appColors.headerTextColor,
            bgcolor:
              cardValue === 1 ? appColors.lightblue : appColors.cardColor,
            cursor: "pointer",
            fontSize: "0.75rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={() => handelRefillOnetimeSale(1)}
        >
          <img
            src={drops}
            alt="Bookwater"
            style={{ width: "1.5rem", height: "1.3rem", marginRight: ".3rem" }}
          />
          <span> Refill</span>
        </Card>
        <Card
          sx={{
            padding: ".5rem",
            borderRadius: appTheme.fontSizeMedium,
            boxShadow: 2,
            width: { xs: "100%", sm: "11rem" },
            color:
              cardValue === 2
                ? appColors.textColorNew
                : appColors.headerTextColor,
            bgcolor:
              cardValue === 2 ? appColors.lightblue : appColors.cardColor,
            cursor: "pointer",
            fontSize: "0.75rem",
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
          }}
          onClick={() => handelRefillOnetimeSale(2)}
        >
          {" "}
          <img
            src={cart}
            alt="Bookwater"
            style={{ width: "1.3rem", height: "1.3rem", marginRight: ".3rem" }}
          />
          Purchase
        </Card>
      </Box>
      <Box sx={bookingCards.bookingMainBox}>
        {productsData?.map((data, index) => {
          return (
            <Card
              key={index}
              sx={{
                boxShadow: 2,
                elevation: 2,
                margin: ".2rem",
                padding: "0 0.5rem 0 0",
                width: { xs: "calc(100% - 0.6rem)", sm: "23rem" },
                height: "100%",
                padding: ".3rem .5rem 0 0",
                position: "relative",
                bgcolor: appColors.cardColor,
                borderRadius: ".7rem",
              }}
            >
              <Grid container>
                <Grid
                  item
                  xs={3}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      margin: ".1rem",
                      height: "5rem",
                      width: "100%",
                      overflow: "hidden",
                    }}
                  >
                    <ImageLoader
                      filename={data.image}
                      styles={{
                        width: "100%",
                        height: "5rem",
                        objectFit: "contain",
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={9}>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignContent="center"
                    sx={{ height: "100%" }}
                    rowGap={0.5}
                  >
                    <Grid item xs={12}>
                      <Typography
                        sx={{
                          color: appColors.headerTextColor,
                          fontSize: appTheme.fontSizeMedium,
                        }}
                      >
                        <span style={{ fontWeight: "600" }}>{data?.name}</span>
                      </Typography>
                    </Grid>
                    <Grid container justifyContent="space-between">
                      <Grid item xs={8}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "flex-start",
                            flexDirection: "column",
                            height: "100%",
                            justifyContent: "center",
                            gap: 0.5,
                          }}
                        >
                          <Typography>
                            {(data.refill.mrp !== data?.refill.sp &&
                              products.booking_type === 1) ||
                            (data.one_time_sale.mrp !==
                              data?.one_time_sale.sp &&
                              products.booking_type === 2) ? (
                              <span
                                style={{
                                  textDecoration: "line-through",
                                  color: appColors.darkGray,
                                  fontWeight: "bold",
                                  fontSize: appTheme.fontSizeSmall,
                                  marginRight: ".6rem",
                                }}
                              >
                                ₹&nbsp;
                                {products.booking_type === 1
                                  ? data.refill.mrp
                                  : data?.one_time_sale.mrp}
                              </span>
                            ) : null}
                            <span
                              style={{
                                color: appColors.headerTextColor,

                                fontWeight: "bold",
                              }}
                            >
                              ₹&nbsp;
                              {products.booking_type === 1
                                ? data.refill.sp
                                : data?.one_time_sale.sp}
                            </span>
                          </Typography>
                          {(data?.sku_qty[0] > 1 &&
                            data.quantity < data.min_book_qty) ||
                          (data?.quantity === undefined &&
                            data?.sku_qty[0] > 1) ? (
                            <Typography
                              sx={{
                                fontWeight: "bold",
                                color: appColors.darkGray,
                                fontSize: appTheme.fontSizeMedium,
                              }}
                            >
                              <span>{data.min_book_qty} Pack |</span>
                              <span>
                                &nbsp;{data.sku_qty[0] * data.min_book_qty}{" "}
                                Units
                              </span>
                            </Typography>
                          ) : null}
                          {data?.sku_qty[0] > 1 &&
                            data?.quantity > 0 &&
                            data.quantity >= data.min_book_qty && (
                              <Typography
                                sx={{
                                  fontWeight: "bold",
                                  color: appColors.darkGray,
                                  fontSize: appTheme.fontSizeMedium,
                                }}
                              >
                                <span>{data.quantity} Pack |</span>
                                {data.quantity >= data.min_book_qty ? (
                                  <span>
                                    &nbsp;
                                    {data.quantity * data.sku_qty[0]} Units
                                  </span>
                                ) : null}
                              </Typography>
                            )}
                          {data?.sku_qty[0] === 1 && (
                            <Typography
                              sx={{
                                fontWeight: "bold",
                                color: appColors.darkGray,
                                fontSize: appTheme.fontSizeMedium,
                              }}
                            >
                              <span>Minimum Qty {data.min_book_qty}</span>
                            </Typography>
                          )}
                          {data.quantity >= data.min_book_qty ? (
                            <Box
                              sx={{
                                display: "flex",
                                fontSize: appTheme.fontSizeSmall,
                                justifyContent: "space-between",
                                marginBottom: ".3rem",
                                alignItems: "center",
                                width: "100%",
                              }}
                            >
                              <Box
                                onClick={() => handelDeleteProduct(data)}
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "flex-start",
                                }}
                              >
                                <span>
                                  <DeleteIcon
                                    sx={{
                                      color: appColors.textColorNew,
                                      height: "1.2rem",
                                      margin: 0,
                                    }}
                                  />
                                </span>
                                <span
                                  style={{
                                    color: appColors.darkGray,
                                    fontSize: appTheme.fontSizeSmall,
                                  }}
                                >
                                  Remove
                                </span>{" "}
                              </Box>
                              {/* <Box onClick={() => handelInfoIcon(data)}>
                                <span>
                                  <img
                                    alt="Bookwater"
                                    src={EditIcon}
                                    style={{ height: "1rem", width: "1.5rem" }}
                                  />
                                </span>
                                &nbsp;
                                <span style={{ color: appColors.darkGray }}>
                                  Edit
                                </span>
                              </Box> */}
                            </Box>
                          ) : (
                            <Typography
                              sx={{
                                color: appColors.Textgray,
                                fontSize: appTheme.fontSizexs,
                                marginBottom: ".3rem",
                              }}
                              onClick={() => handelInfoIcon(data, "water")}
                            >
                              <span>Description: </span>
                              <span>
                                {data.description.length > 10
                                  ? data.description.slice(0, 13) + "..."
                                  : data.description}
                              </span>
                            </Typography>
                          )}
                        </Box>
                      </Grid>
                      <Grid item xs={4}>
                        {data.quantity >= data.min_book_qty ? (
                          <Box>
                            {" "}
                            <Typography
                              sx={{
                                fontWeight: 500,
                                color: appColors.darkGray,
                                fontSize: appTheme.fontSizeSmall,
                              }}
                            >
                              Required
                            </Typography>
                            <Box>
                              <RequiredButton
                                handelAddQuantity={handelAddQuantity}
                                handelReduseQuantity={handelReduseQuantity}
                                data={data}
                              />
                            </Box>
                            {returnCanFlags[data.id] && cardValue === 1 ? (
                              <>
                                <Typography
                                  sx={{
                                    fontWeight: 500,
                                    color: appColors.darkGray,
                                    fontSize: appTheme.fontSizeSmall,
                                  }}
                                >
                                  Return
                                </Typography>
                                <Box>
                                  <ReturnButton
                                    handelAddReturnQuantity={
                                      handelAddReturnQuantity
                                    }
                                    handelReduseReturnQuantity={
                                      handelReduseReturnQuantity
                                    }
                                    data={data}
                                  />
                                </Box>
                              </>
                            ) : null}
                          </Box>
                        ) : (
                          <LoadingButton
                            variant="contained"
                            loading={loadingStates[data.id]}
                            sx={{
                              bgcolor: appColors.textColorNew,
                              height: "1.8rem",
                              color: "#fff",
                              marginTop: ".5rem",
                              fontSize: appTheme.fontSizeSmall,
                            }}
                            onClick={() => handelAddProducts(data)}
                            size="small"
                          >
                            Add +
                          </LoadingButton>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {data.quantity >= data.min_book_qty && cardValue === 1 && (
                <Box
                  sx={{
                    bgcolor: "#bfefff",
                    display: "flex",
                    justifyContent: "space-between",
                    width: "102%",
                    // position: "absolute",
                    bottom: 0,
                  }}
                >
                  <span
                    style={{
                      color: "#58595b",
                      bottom: 0,
                      marginLeft: ".5rem",
                      fontSize: appTheme.fontSizeSmall,
                      fontWeight: "bold",
                    }}
                  >
                    Return &nbsp;
                    {data?.return === undefined
                      ? getMaxReturn(data?.capacity, data?.material)
                      : data?.return}
                  </span>
                  <span
                    style={{
                      color: "#58595b",
                      bottom: 0,
                      right: 0,
                      fontSize: appTheme.fontSizeSmall,
                    }}
                  >
                    {!returnCanFlags[data.id] ? "More" : "Close"}
                    &nbsp;
                    <span
                      style={{
                        backgroundColor: appColors.textColorNew,
                        color: "#fff",
                        paading: ".4rem",
                        borderRadius: ".2rem",
                      }}
                      onClick={() => handelOpenReturnButton(data)}
                    >
                      {returnCanFlags[data.id] ? (
                        <KeyboardArrowUpIcon
                          sx={{ fontSize: appTheme.fontSizeLarge }}
                        />
                      ) : (
                        <KeyboardArrowDownIcon
                          sx={{ fontSize: appTheme.fontSizeLarge }}
                        />
                      )}
                    </span>
                  </span>
                </Box>
              )}
              {(data?.crate_details?.length > 0 &&
                data?.quantity < data?.min_book_qty &&
                false) ||
              (data?.crate_details?.length > 0 &&
                data?.quantity === undefined &&
                false) ? (
                <Box
                  sx={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    borderRadius: "0", // No rounding
                    borderBottomLeftRadius: "13px",
                  }}
                >
                  <Box sx={{ position: "relative", zIndex: 1 }}>
                    <Typography
                      sx={{
                        position: "absolute",
                        bottom: 0, // Adjust position as needed
                        left: 7, // Adjust position as needed
                        zIndex: 2,
                        color: appColors.white,
                        fontSize: ".6rem",
                        width: "3rem",
                      }}
                    >
                      Crate Available
                    </Typography>
                    <img
                      alt="bookwater"
                      src={crateAvailableImage}
                      style={{
                        width: "6rem",
                      }}
                    />
                  </Box>
                </Box>
              ) : (
                ""
              )}

              {true && (
                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    bgcolor: appColors.textColorNew,
                    borderRadius: "0", // Set the border radius to 0 (no rounding)
                    borderTopRightRadius: "8px",
                    borderBottomLeftRadius: "13px",
                    boxShadow: 1,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: ".7rem",
                      color: "#fff",
                      padding: ".1rem .1rem 0 .3rem",
                    }}
                  >
                    &nbsp;{" "}
                    {data?.is_filling_product
                      ? cardValue === 1
                        ? "Refill"
                        : "One-Time"
                      : "Sale"}
                    &nbsp;
                  </Typography>
                </Box>
              )}
              <Box
                sx={{
                  position: "absolute",
                  top: -2,
                  left: 6,
                  cursor: "pointer",
                }}
                onClick={() => handelInfoIcon(data, "water")}
              >
                <img src={iIcon} alt="" />
              </Box>
            </Card>
          );
        })}
      </Box>
      {!isOneTimeProduct &&
      cardValue === 2 &&
      SkuProductDetails?.productDetails !== undefined ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img src={failedOrder} alt="Bookwater" style={{ width: "10rem" }} />
          <Typography
            sx={{
              fontSize: appTheme.fontSizeMedium,
              color: appColors.darkGray,
              fontWeight: "600",
            }}
          >
            {" "}
            We will be delivering shortly in your locality.
          </Typography>
        </Box>
      ) : (
        ""
      )}

      {isOrderInPaymentPending && (
        <PendingOrderDialogBox
          open={isOrderInPaymentPending}
          handleClose={handlePaymentPendingDialog}
          paymentPendingDetails={paymentPendingOrderId}
          setDialogClose={setIsOrderPaymentPending}
        />
      )}

      {/* {openCrateSelectionDialog && (
        <CrateSelectionBottomSheet
          open={openCrateSelectionDialog}
          setOpen={setOpenCrateSelectionDialog}
          selectedProduct={selectedProduct}
          cardValue={cardValue}
        />
      )} */}

      {infoCard && (
        <ProductInfoDialog
          toggleDrawer={toggleDrawer}
          open={infoCard}
          setOpen={setInfoCard}
          selectedProduct={selectedProduct}
          bookingType={cardValue}
        />
      )}

      {crateInfoOpen && (
        <CrateInfoDialog
          toggleDrawer={toggleDrawer}
          open={crateInfoOpen}
          setOpen={setCrateInfoOpen}
          selectedProduct={selectedProduct}
          bookingType={cardValue}
        />
      )}
    </>
  );
}
