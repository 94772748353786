/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Gowtham Prasath
 * @description Booking.jsx is the parent file for the booking page
 * @date
 * @version
 */

import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import moment from "moment";
import KeyboardBackspaceTwoToneIcon from "@mui/icons-material/KeyboardBackspaceTwoTone";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { useDispatch, useSelector } from "react-redux";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Slide from "@mui/material/Slide";
import { Box } from "@mui/material";
import appColors from "../../../Assets/AppTheme/appColors";
import { styles } from "./filtercss";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import "../../../Components/styles.css";
import { makeStyles } from "@mui/styles";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function GiftCardFilterDialog({
  filterDialogOpen,
  setfilterDialogOpen,
  handleFilterSubmit,
  setPageNumber,
  value,
  requestParams,
  payMentStatus,
  setPayMentStatus,
  sortValue,
  toDateValue,
  setToDateValue,
  setSortValue,
  fromDateValue,
  setFromDateValue,
  category,
  setCategory,
}) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const today = moment().add(7, "days").format("YYYY-MM-DD");

  const filterOption = ["PAYSHARP", "RAZORPAY", "WATERWALLET"];
  //state for the value of dates
  const [buttonDetails, setButtonDetails] = React.useState("");

  const useStyles = makeStyles({
    button: {
      color: "#fff",
      fontSize: ".7rem",
      "&:hover": {
        backgroundColor: appColors.lightGreen,
      },
    },
    activeButton: {
      backgroundColor: appColors.lightGreen,
    },
    inactiveButton: {
      backgroundColor: appColors.textColorNew,
    },
    buttonContainer: {
      display: "flex",
      gap: "8px", // adjust the gap as needed
    },
  });
  //function to set filter from value
  const handleChangeFrom = (e) => {
    setFromDateValue(e.target.value);
    const momentDate = moment(e.target.value).format("YYYY-MM-DD");
    console.log(momentDate);
  };

  //function to close dialog
  const handleClose = () => {
    setfilterDialogOpen(false);
  };

  //function to sort filter option
  const SortFilterButton = (value) => {
    setPayMentStatus(value);
  };

  const sortByFilterCategory=(value)=>{
    setCategory(value)
  }
  //function to handel sort by date range
  const handelSortByDateRange = (value) => {
    setSortValue(value);
  };
  //function to handel change to
  const handleChangeTo = (e) => {
    setToDateValue(e.target.value);
    const momentDate = moment(e.target.value).format("YYYY-MM-DD");
  };

  //function to handel apply filter button
  const handelApplyFilter = () => {
    setPageNumber(1);
    handleFilterSubmit();
    handleClose();
  };

  //function to handel clear filter option
  const handelApplyClearAllFilter = () => {
    const filterObject = {
      status:null,
      sort:"DESC",
      from: moment().subtract(180, "days").format("YYYY-MM-DD"),
      to: moment().format("YYYY-MM-DD"),
      category:null,
      pageNumber:0,
      pageSize: 10,
    };
    handleFilterSubmit(filterObject);
    setPayMentStatus(null);
    setButtonDetails("");
    setFromDateValue(moment().subtract(180, "days").format("YYYY-MM-DD"));
    setSortValue("DESC");
    setToDateValue(moment().format("YYYY-MM-DD"));
    setCategory(null)
    handleClose();
  };

  //function to handel buuton click
  const handelButonClick = (data) => {
    setButtonDetails(data);
  };

  console.log(payMentStatus);
  //function for rendring option filter button
  const OptionButton = () => {
    return (
      <>
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
          {filterOption.map((item, index) => (
            <Button
              size="small"
              variant="contained"
              sx={{
                backgroundColor:
                  buttonDetails !== item
                    ? appColors.textColorNew
                    : appColors.lightGreen,
                color: appColors.white,
                "&:hover": {
                  backgroundColor:
                    buttonDetails !== item
                      ? appColors.textColorNew
                      : appColors.lightGreen,
                },
                "&:hover": {
                  backgroundColor: appColors.lightGreen,
                },
              }}
              onClick={() => handelButonClick(item)}
              key={item}
            >
              {item}
            </Button>
          ))}
        </Box>
      </>
    );
  };

  const classes = useStyles();
  return (
    <div>
      <Dialog
        open={filterDialogOpen}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar
          sx={{ position: "relative", backgroundColor: appColors.cardColor }}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
              sx={{ color: appColors.secondaryText }}
            >
              <KeyboardBackspaceTwoToneIcon />
            </IconButton>
            <Typography
              sx={{ ml: 2, flex: 1, color: appColors.secondaryText }}
              variant="h6"
              component="div"
            >
              Back
            </Typography>
          </Toolbar>
        </AppBar>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            backgroundColor: appColors.cardColor,
            padding: "1rem",
            minWidth: "280px",
          }}
        >
          <Typography
            sx={{
              fontSize: "1.2rem",
              color: appColors.secondaryText,
              fontWeight: "bold",
              marginBottom: "1rem",
            }}
          >
            Filters
          </Typography>
          <Typography sx={styles.buttonHeader}>Sort By category</Typography>
          <Box sx={styles.ButtonContainer}>
            <Button
              size="small"
              variant="contained"
              className={`${classes.button} ${
                category !== null
                  ? classes.inactiveButton
                  : classes.activeButton
              }`}
              onClick={() => sortByFilterCategory(null)}
            >
              <span style={{ fontSize: ".7rem" }}>All</span>
            </Button>
            <Button
              size="small"
              variant="contained"
              className={`${classes.button} ${
                category !== "RECEIVED"
                  ? classes.inactiveButton
                  : classes.activeButton
              }`}
              onClick={() => sortByFilterCategory("RECEIVED")}
            >
              <span style={{ fontSize: ".7rem" }}>Received</span>
            </Button>
            <Button
              size="small"
              variant="contained"
              className={`${classes.button} ${
                category !== "SENT"
                  ? classes.inactiveButton
                  : classes.activeButton
              }`}
              onClick={() => sortByFilterCategory("SENT")}
            >
              <span style={{ fontSize: ".7rem" }}>Sent</span>
            </Button>
          </Box>
          <Typography sx={styles.buttonHeader}>Sort By Status</Typography>
          <Box sx={styles.ButtonContainer}>
            <Button
              size="small"
              variant="contained"
              className={`${classes.button} ${
                payMentStatus !== null
                  ? classes.inactiveButton
                  : classes.activeButton
              }`}
              onClick={() => SortFilterButton(null)}
            >
              <span style={{ fontSize: ".7rem" }}>All</span>
            </Button>
            <Button
              size="small"
              variant="contained"
              className={`${classes.button} ${
                payMentStatus !== 100
                  ? classes.inactiveButton
                  : classes.activeButton
              }`}
              onClick={() => SortFilterButton(100)}
            >
              <span style={{ fontSize: ".7rem" }}>Payment Pending</span>
            </Button>
            <Button
              size="small"
              variant="contained"
              className={`${classes.button} ${
                payMentStatus !== 200
                  ? classes.inactiveButton
                  : classes.activeButton
              }`}
              onClick={() => SortFilterButton(200)}
            >
              <span style={{ fontSize: ".7rem" }}>Claim</span>
            </Button>
            <Button
              size="small"
              variant="contained"
              className={`${classes.button} ${
                payMentStatus !== 300
                  ? classes.inactiveButton
                  : classes.activeButton
              }`}
              onClick={() => SortFilterButton(300)}
            >
              <span style={{ fontSize: ".7rem" }}>Claimed</span>
            </Button>
            <Button
              size="small"
              variant="contained"
              className={`${classes.button} ${
                payMentStatus !== 400
                  ? classes.inactiveButton
                  : classes.activeButton
              }`}
              onClick={() => SortFilterButton(400)}
            >
              <span style={{ fontSize: ".7rem" }}>Expired</span>
            </Button>
          </Box>
          {value === 0 && (
            <>
              <Typography sx={styles.buttonHeader}>
                Sort By Payment Method
              </Typography>
              <Box sx={styles.ButtonContainer}>
                <OptionButton />
              </Box>
            </>
          )}

          <Typography sx={styles.buttonHeader}>Sort By Date</Typography>
          <Box sx={styles.ButtonContainer}>
            <Button
              size="small"
              variant="contained"
              className={`${classes.button} ${
                sortValue !== "DESC"
                  ? classes.inactiveButton
                  : classes.activeButton
              }`}
              onClick={() => handelSortByDateRange("DESC")}
            >
              <span style={{ fontSize: ".7rem" }}>Recent First</span>
            </Button>
            <Button
              size="small"
              variant="contained"
              className={`${classes.button} ${
                sortValue !== "ASC"
                  ? classes.inactiveButton
                  : classes.activeButton
              }`}
              onClick={() => handelSortByDateRange("ASC")}
            >
              <span style={{ fontSize: ".7rem" }}>Old First</span>
            </Button>
          </Box>

          <Typography sx={styles.buttonHeader}>Sort by Date Range</Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: { md: "row", xs: "column" },
              gap: 1,
            }}
          >
            <Box>
              <label
                htmlFor="fromDay"
                style={{
                  fontSize: "12px",
                  fontWeight: "600",
                  color: appColors.darkGray,
                }}
              >
                From:
              </label>
              <input
                type="date"
                id="fromDay"
                name="from"
                value={fromDateValue}
                onChange={handleChangeFrom}
                style={{
                  backgroundColor: appColors.textColorNew,
                  border: 0,
                  color: appColors.white,
                  padding: "10px",
                  fontSize: "12px",
                  height: "30px",
                  fontWeight: "500",
                  borderRadius: "10px",
                  marginLeft: "5px",
                  width: "120px",
                }}
                max={today}
              />
            </Box>
            <Box>
              <label
                htmlFor="toDay"
                style={{
                  fontSize: "12px",
                  fontWeight: "600",
                  color: appColors.darkGray,
                }}
              >
                To:&nbsp;&nbsp;&nbsp;&nbsp;
              </label>
              <input
                type="date"
                id="toDay"
                name="to"
                value={toDateValue}
                onChange={handleChangeTo}
                style={{
                  backgroundColor: appColors.textColorNew,
                  border: 0,
                  color: appColors.white,
                  padding: "10px",
                  fontSize: "12px",
                  height: "30px",
                  fontWeight: "500",
                  width: "120px",
                  borderRadius: "10px",
                  marginLeft: "5px",
                }}
              />
            </Box>
          </Box>

          <Box className="box-align" sx={{ marginTop: "2rem", gap: 1 }}>
            <Button sx={styles.applyFilter} onClick={handelApplyClearAllFilter}>
              Clear
            </Button>
            <Button sx={styles.applyFilter} onClick={handelApplyFilter}>
              Apply
            </Button>
          </Box>
        </Box>
      </Dialog>
    </div>
  );
}
